import { Modal, Typography, Divider } from '@mui/material';
import React, { FC } from 'react';
import { TalkShowEpisodeDataInput } from '../generated/graphql';
import { ModalContent } from './ModalContent';
import { TalkShowEpisodeForm } from './form';

export type TalkShowEpisodeAddModalProps = {
  open: boolean;
  onSave?: (data: TalkShowEpisodeDataInput) => Promise<void>;
  onCancel?: () => void;
};

export const TalkShowEpisodeAddModal: FC<TalkShowEpisodeAddModalProps> = ({
  open,
  onSave,
  onCancel
}) => {
  return (
    <Modal open={open}>
      <ModalContent sx={{ width: '45%', maxHeight: '95vh', overflow: 'auto' }}>
        <Typography variant="h5" sx={{ m: 2 }}>
          Add Episode
        </Typography>
        <Divider />
        <TalkShowEpisodeForm onSave={onSave} onCancel={onCancel} />
      </ModalContent>
    </Modal>
  );
};
