import {
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Icon
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback, useEffect, useState, MouseEvent } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  Container,
  ContainerHeader,
  Center,
  ISeriesType,
  SeriesForm
} from '../../components';
import {
  useSeriesQuery,
  useUpdateSeriesMutation,
  SeriesDataUpdateInput,
  ContentType
} from '../../generated/graphql';
import { useMobileAddMutation } from '../../hooks';

type ContentProps = {
  series?: ISeriesType;
  loading: boolean;
  onSave?: (data: SeriesDataUpdateInput) => Promise<void>;
};

const Content: FC<ContentProps> = ({ series, loading, onSave }) => {
  if (loading) {
    return (
      <Center sx={{ flex: 1 }}>
        <CircularProgress />
      </Center>
    );
  }

  if (!series) {
    return (
      <Center sx={{ flex: 1 }}>
        <Typography>No Result found!</Typography>
      </Center>
    );
  }

  return <SeriesForm series={series} onUpdate={onSave} />;
};

export const Series: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const {
    data,
    isLoading: loading,
    error
  } = useSeriesQuery({ id }, { enabled: !!id });
  const updateSeries = useUpdateSeriesMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useSeriesQuery.getKey({ id }));
    }
  });
  const { addLatest, addSlide, addTrending } = useMobileAddMutation();

  const handleMenuClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Series ({id})</Typography>
        <IconButton onClick={handleMenuClick}>
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: '500',
              px: 2,
              py: 1,
              color: 'rgba(255, 255, 255, 0.5)'
            }}
          >
            Mobile
          </Typography>
          <MenuItem
            onClick={async () => {
              await addSlide({
                contentId: id,
                contentType: ContentType.Series,
                rank: 0
              });

              handleMenuClose();
            }}
          >
            Add to Slide
          </MenuItem>
          <MenuItem
            onClick={async () => {
              await addLatest({
                contentId: id,
                contentType: ContentType.Series,
                rank: 0
              });

              handleMenuClose();
            }}
          >
            Add to Latest
          </MenuItem>
          <MenuItem
            onClick={async () => {
              await addTrending({
                contentId: id,
                contentType: ContentType.Series,
                rank: 0
              });

              handleMenuClose();
            }}
          >
            Add to Trending
          </MenuItem>
        </Menu>
      </ContainerHeader>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flex: 1
        }}
      >
        <Content
          loading={loading}
          series={data?.series ?? undefined}
          onSave={async data => {
            await updateSeries.mutateAsync({
              id,
              data
            });
          }}
        />
      </Box>
    </Container>
  );
};
