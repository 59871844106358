import create from 'zustand';
import { User } from '../../generated/graphql';

export type IUser = User;

export type IUserStore = {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
};

export const useUserStore = create<IUserStore>(set => ({
  user: null,
  setUser: user => {
    set(() => ({ user }));
  }
}));

export const useUser = () => useUserStore(e => e.user);
export const useSetUser = () => useUserStore(e => e.setUser);
