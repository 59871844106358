import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Icon,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLogIn } from '../../service/auth';

export const LogIn: FC = () => {
  const [error, setError] = useState<string>();
  const logIn = useLogIn();

  const {
    values,
    touched,
    errors,
    isValid,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur
  } = useFormik({
    initialValues: {
      phone: '',
      password: ''
    },
    onSubmit: async data => {
      try {
        await logIn({
          phone: data.phone,
          password: data.password,
          aud: 'admin'
        });
      } catch (e) {
        console.error(e);
      }
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Avatar
        sx={{
          margin: 1,
          backgroundColor: 'theme.platte.secondary.main'
        }}
      >
        <Icon>lock_outlined</Icon>
      </Avatar>
      <Typography variant="h5">Log In</Typography>
      <Alert
        sx={{
          my: 1,
          ...(!error && { display: 'none' })
        }}
        severity="error"
        onClose={() => {
          setError(undefined);
        }}
      >
        Username or Password incorrect!
      </Alert>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Phone"
          name="phone"
          autoComplete="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phone && Boolean(errors.phone)}
          helperText={touched.phone && errors.phone}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isValid}
          sx={{
            my: 2
          }}
        >
          Sign In &nbsp;
          {isSubmitting && <CircularProgress size={20} color="inherit" />}
        </Button>
        <Box>
          <Link
            to="/auth/forgot-password"
            variant="body2"
            component={RouterLink}
          >
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
