import { Typography, TextField, IconButton, Icon } from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import React, { FC, useEffect, useState } from 'react';
import { InfoInputContainer } from '../../InfoInputContainer';
import moment, { Moment } from 'moment';

export type ReleasedDateInputProps = {
  releasedDate: string | null;
  onSave?: (releasedDate: Date | null) => Promise<void>;
};

export const ReleasedDateInput: FC<ReleasedDateInputProps> = ({
  releasedDate,
  onSave
}) => {
  const [state, setState] = useState<'edit' | 'view'>('view');
  const [value, setValue] = useState<Moment | null>(null);

  useEffect(() => {
    setValue(releasedDate ? moment(releasedDate) : moment(new Date()));
  }, [releasedDate]);

  return (
    <InfoInputContainer>
      {state === 'view' ? (
        <>
          <Typography variant="subtitle1" sx={{ mr: 3, fontWeight: 'bold' }}>
            Released Date
          </Typography>
          <Typography sx={{ fontStyle: 'italic' }}>
            {releasedDate && value
              ? value.format('Do MMM, YYYY, h:mm:ss a')
              : 'N/A'}
          </Typography>
        </>
      ) : (
        <DateTimePicker
          value={value}
          onChange={date => {
            setValue(date);
          }}
          renderInput={params => (
            <TextField
              variant="standard"
              fullWidth
              label="Released Date"
              {...params}
            />
          )}
        />
      )}

      <IconButton
        className={state === 'view' ? 'action' : ''}
        size="small"
        sx={{ ml: 1 }}
        onClick={async () => {
          if (state === 'edit') {
            if (onSave) {
              await onSave(value?.toDate() ?? null);
            }
          }

          setState(s => (s === 'view' ? 'edit' : 'view'));
        }}
      >
        <Icon>{state === 'view' ? 'edit' : 'done'}</Icon>
      </IconButton>
    </InfoInputContainer>
  );
};
