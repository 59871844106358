import {
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputBase,
  Paper,
  TablePagination,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, ContainerHeader } from '../../components';
import { useMusicsQuery } from '../../generated/graphql';
import { usePaginationState } from '../../hooks';

const MusicListAside: FC = () => {
  const [searchKeyword, setSearchKeyword] = useState('');

  return (
    <Box
      sx={{
        flex: 1
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          px: 1.5,
          pl: 2.3,
          py: 0.4,
          alignItems: 'center'
        }}
        elevation={2}
      >
        <InputBase
          placeholder="Search"
          value={searchKeyword}
          onChange={e => {
            setSearchKeyword(e.target.value);
          }}
        />
        <IconButton
          size="large"
          sx={{ p: 1 }}
          onClick={() => {
            console.log(searchKeyword);
          }}
        >
          <Icon>search</Icon>
        </IconButton>
      </Paper>
    </Box>
  );
};

export const MusicList: FC = () => {
  const { push } = useHistory();
  const { page, pageSize, query, setPage } = usePaginationState();
  const { data } = useMusicsQuery({
    query
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Musics</Typography>
        <IconButton onClick={() => push('/music/add')}>
          <Icon>add_box</Icon>
        </IconButton>
      </ContainerHeader>
      <Box sx={{ display: 'flex', flex: 1, p: 2 }}>
        <MusicListAside />
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 4, ml: 1 }}>
          <ImageList component="div" cols={5} sx={{ m: 0, overflow: 'hidden' }}>
            {(data?.musics?.items ?? []).map(m => (
              <ImageListItem
                key={m.id}
                component={Link}
                to={`/music/${m.id}`}
                sx={{
                  cursor: 'pointer',
                  transition: 'all 0.1s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.01)'
                  }
                }}
              >
                <img
                  style={{ width: '100%', aspectRatio: '0.75' }}
                  src={m.thumbnail ?? 'http://lorempixel.com/320/460/nature'}
                  alt={m.title}
                  loading="lazy"
                />
                <ImageListItemBar title={m.title} />
              </ImageListItem>
            ))}
          </ImageList>
          <Box sx={{ flex: 1 }} />
          <TablePagination
            component="div"
            count={data?.musics.count ?? 0}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            onPageChange={(_, p) => {
              setPage(p);
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};
