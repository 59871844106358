import React, { FC, useState } from 'react';
import {
  AppBar as Appbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDrawer, useTitle } from '../store/ui';
import { useLogOut } from '../service/auth';

export const AppBar: FC = () => {
  const theme = useTheme();
  const { isDrawerOpen, setDrawerOpen, drawerWidth } = useDrawer(e => ({
    setDrawerOpen: e.setOpen,
    isDrawerOpen: e.open,
    drawerWidth: e.width
  }));
  const title = useTitle(e => e.title);
  const [isLogOutDialogOpen, setLogOutDialogOpen] = useState(false);
  const logOut = useLogOut();

  return (
    <Appbar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        ...(isDrawerOpen && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          })
        })
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          size="large"
          color="inherit"
          sx={{ mr: 2, ...(isDrawerOpen && { display: 'none' }) }}
          onClick={() => setDrawerOpen(true)}
        >
          <Icon>menu</Icon>
        </IconButton>
        <Typography variant="h6" component="div" noWrap sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton
          onClick={() => setLogOutDialogOpen(true)}
          color="inherit"
          size="large"
        >
          <Icon>exit_to_app</Icon>
        </IconButton>
      </Toolbar>
      <Dialog
        open={isLogOutDialogOpen}
        onClose={() => setLogOutDialogOpen(false)}
      >
        <DialogTitle>Log Out ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Log Out ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setLogOutDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              await logOut();
            }}
          >
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </Appbar>
  );
};
