import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Container, ContainerHeader, MovieForm } from '../../components';
import {
  useCreateMovieMutation,
  useMoviesQuery
} from '../../generated/graphql';

export const MovieAdd: FC = () => {
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  const createMovie = useCreateMovieMutation({
    onSuccess: ({ createMovie }) => {
      // TODO: Make MoviesQuery variable optional
      queryClient.invalidateQueries([
        useMoviesQuery.getKey({ query: { take: 20, skip: 0 } })[0]
      ]);

      replace(`/movie/${createMovie.id}`);
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Add Movie</Typography>
      </ContainerHeader>
      <Box sx={{ p: 2 }}>
        <MovieForm
          onSave={async data => {
            await createMovie.mutateAsync({ data });
          }}
        />
      </Box>
    </Container>
  );
};
