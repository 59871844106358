import {
  Box,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TablePagination,
  Typography
} from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, ContainerHeader } from '../../components';
import { useTalkShowsQuery } from '../../generated/graphql';
import { usePaginationState } from '../../hooks';

export const TalkShowList: FC = () => {
  const { push } = useHistory();
  const { page, pageSize, query, setPage } = usePaginationState();
  const { data } = useTalkShowsQuery({ query });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Talk Shows</Typography>
        <IconButton onClick={() => push('/show/add')}>
          <Icon>add_box</Icon>
        </IconButton>
      </ContainerHeader>
      <Box sx={{ display: 'flex', flex: 1, p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 4, ml: 1 }}>
          <ImageList component="div" cols={6} sx={{ m: 0, overflow: 'hidden' }}>
            {(data?.talkShowAll?.items ?? []).map(m => (
              <ImageListItem
                key={m.id}
                component={Link}
                to={`/show/${m.id}`}
                sx={{
                  cursor: 'pointer',
                  transition: 'all 0.1s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.01)'
                  }
                }}
              >
                <img
                  style={{ width: '100%', aspectRatio: '0.75' }}
                  src={m.thumbnail ?? 'http://lorempixel.com/320/460/nature'}
                  alt={m.title}
                  loading="lazy"
                />
                <ImageListItemBar title={m.title} />
              </ImageListItem>
            ))}
          </ImageList>
          <Box sx={{ flex: 1 }} />
          <TablePagination
            component="div"
            count={data?.talkShowAll.count ?? 0}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            onPageChange={(_, p) => {
              setPage(p);
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};
