import { Typography, IconButton, Icon } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { CurrencyInput } from '../../CurrencyInput';
import { formatCurrency } from '../../../utils';

export type PriceInputProps = {
  price: number | null;
  onSave?: (price: number | null) => Promise<void>;
};

export const PriceInput: FC<PriceInputProps> = ({ price, onSave }) => {
  const [state, setState] = useState<'edit' | 'view'>('view');
  const [value, setValue] = useState(price);

  useEffect(() => {
    setValue(price);
  }, [price]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        '& > .action': {
          opacity: 0,
          transition: 'all 0.3s ease-in-out'
        },
        '&:hover > .action': {
          opacity: 1
        }
      }}
    >
      {state === 'view' ? (
        <>
          <Typography variant="subtitle1" sx={{ mr: 3, fontWeight: 'bold' }}>
            Price
          </Typography>
          {price ? (
            <Typography sx={{ fontStyle: 'italic' }}>
              &#8377; {formatCurrency(String(price / 100))}
            </Typography>
          ) : (
            <Typography sx={{ fontStyle: 'italic' }}>Free</Typography>
          )}
        </>
      ) : (
        <CurrencyInput
          fullWidth
          label="Price"
          sx={{ flex: 1 }}
          variant="standard"
          value={Number(value ?? 0)}
          onValueChange={e => setValue(e)}
        />
      )}

      <IconButton
        className={state === 'view' ? 'action' : ''}
        size="small"
        sx={{ ml: 1 }}
        onClick={async () => {
          if (state === 'edit') {
            if (onSave) {
              await onSave(value);
            }
          }

          setState(s => (s === 'view' ? 'edit' : 'view'));
        }}
      >
        <Icon>{state === 'view' ? 'edit' : 'done'}</Icon>
      </IconButton>
    </Box>
  );
};
