import { LoadingButton } from '@mui/lab';
import {
  ButtonGroup,
  Button,
  FormLabel,
  Grid,
  Icon,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  SubscriptionPlanDataInput,
  SubscriptionPlanDataUpdateInput,
  SubscriptionPlanPrice
} from '../../generated/graphql';
import { getChanged, isEmpty } from '../../utils';
import {
  SubscriptionPlanPrices,
  SubscriptionPlanPricesProps
} from '../SubscriptionPlanPrices';

const videoQualities = [480, 720, 1080];
const booleanProperties = [
  'download',
  'originals',
  'tvod',
  'ads',
  'background',
  'coin',
  'pip',
  'active'
] as const;

export type SubscriptionPlanFormProps = {
  plan?: SubscriptionPlanDataInput;
  onSave?: (data: SubscriptionPlanDataInput) => Promise<void>;
  onUpdate?: (data: SubscriptionPlanDataUpdateInput) => Promise<void>;
  onPriceAdd?: SubscriptionPlanPricesProps['onAdd'];
  onPriceUpdate?: SubscriptionPlanPricesProps['onUpdate'];
};

const TextFieldForward = forwardRef((props, ref) => {
  return <TextField inputRef={ref} fullWidth variant="standard" {...props} />;
});

export const SubscriptionPlanForm: FC<SubscriptionPlanFormProps> = ({
  plan,
  onSave,
  onUpdate,
  onPriceAdd,
  onPriceUpdate
}) => {
  const editState = useMemo((): 'add' | 'update' | 'view' => {
    if (onSave) return 'add';
    if (onUpdate) return 'update';
    return 'view';
  }, [onSave, onUpdate]);

  const [changedState, setChangedState] =
    useState<SubscriptionPlanDataUpdateInput>({});

  const { values, isSubmitting, setValues, handleChange, handleSubmit } =
    useFormik<SubscriptionPlanDataInput>({
      initialValues: plan ?? {
        name: '',
        background: false,
        coin: true,
        download: false,
        originals: false,
        pip: false,
        prices: [],
        quality: '480',
        tvod: false,
        offer: null,
        ads: true,
        active: false
      },
      onSubmit: async data => {
        if (onSave) {
          await onSave({
            ...data,
            prices: data.prices.map(p => {
              delete (p as any)['id'];

              return p;
            })
          });
        } else if (onUpdate) {
          await onUpdate(changedState);
        }
      }
    });
  const isOfferNull = useMemo(() => {
    return values.offer === null || values.offer === undefined;
  }, [values.offer]);

  useEffect(() => {
    if (editState === 'update') {
      setChangedState(
        getChanged(
          {
            ...plan,
            prices: undefined
          },
          {
            ...values,
            prices: undefined
          }
        )
      );
    }
  }, [editState, plan, values]);

  useEffect(() => {
    if (plan) {
      setValues(plan);
    }
  }, [plan, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            value={values.name}
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend">Video Quality</FormLabel>

          <ButtonGroup fullWidth>
            {videoQualities.map(q => {
              return (
                <Button
                  key={q}
                  variant={
                    q <= Number(values.quality) ? 'contained' : 'outlined'
                  }
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    handleChange('quality')(String(q));
                  }}
                >
                  {q}p
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Offer"
            control={<Checkbox checked={!isOfferNull} />}
            value={!isOfferNull}
            onChange={() => {
              setValues(c => ({
                ...c,
                offer: isOfferNull ? 0 : null
              }));
            }}
          />

          {!isOfferNull && (
            <CurrencyInput
              suffix="%"
              min={0}
              max={100}
              value={String(values.offer)}
              onValueChange={v => {
                setValues(c => ({
                  ...c,
                  offer: v === undefined ? 0 : Number(v)
                }));
              }}
              customInput={TextFieldForward}
            />
          )}
        </Grid>
        {booleanProperties.map(p => (
          <Grid key={p} item xs={12} sm={6} lg={4}>
            <FormControlLabel
              control={<Checkbox checked={Boolean(values[p])} />}
              label={p}
              value={values[p]}
              onChange={handleChange(p)}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <SubscriptionPlanPrices
            prices={values.prices as SubscriptionPlanPrice[]}
            onAdd={async p => {
              if (editState === 'add') {
                setValues(c => ({
                  ...c,
                  prices: [...c.prices, { id: `toadd-${p.name}`, ...p }]
                }));
              } else if (editState === 'update') {
                if (onPriceAdd) {
                  await onPriceAdd(p);
                }
              }
            }}
            onUpdate={async (i, p) => {
              if (editState === 'add') {
                setValues(c => ({
                  ...c,
                  prices: c.prices.map(pp => {
                    if ((pp as any).id === i) {
                      return {
                        ...pp,
                        ...p
                      } as SubscriptionPlanPrice;
                    }

                    return pp;
                  })
                }));
              } else if (editState === 'update') {
                if (onPriceUpdate) {
                  await onPriceUpdate(i, p);
                }
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            disabled={
              isSubmitting || (editState === 'update' && isEmpty(changedState))
            }
            startIcon={<Icon>save</Icon>}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
