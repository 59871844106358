import axios from 'axios';
import { useState, useMemo, useCallback } from 'react';

export const useFile = () => {
  const [file, setFile] = useState<File | null>(null);
  const url = useMemo(() => (file ? URL.createObjectURL(file) : null), [file]);

  const upload = useCallback(
    async (fileName?: string) => {
      if (!file) {
        return {
          location: null,
          originalName: null,
          key: null
        };
      }

      const formData = new FormData();
      const urlParams = new URLSearchParams();

      formData.append('file', file);
      if (fileName) {
        urlParams.append('name', fileName);
      }

      const { data } = await axios.post<{
        location: string;
        originalName: string;
        key: string;
      }>(`/api/v1/file/upload/image?${urlParams.toString()}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });

      return data;
    },
    [file]
  );

  return {
    file,
    url,
    upload,
    setFile
  };
};
