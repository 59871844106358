import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Container, ContainerHeader, MusicForm } from '../../components';
import {
  useCreateMusicMutation,
  useMusicsQuery
} from '../../generated/graphql';

export const MusicAdd: FC = () => {
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  const createMusic = useCreateMusicMutation({
    onSuccess: ({ createMusic }) => {
      queryClient.invalidateQueries(
        // TODO: Make MusicsQuery variable optional
        [useMusicsQuery.getKey({ query: { take: 20, skip: 0 } })[0]]
      );
      replace(`/music/${createMusic.id}`);
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Add Music</Typography>
      </ContainerHeader>
      <Box sx={{ p: 2 }}>
        <MusicForm
          onSave={async data => {
            await createMusic.mutateAsync({ data });
          }}
        />
      </Box>
    </Container>
  );
};
