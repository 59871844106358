import {
  Paper,
  Typography,
  IconButton,
  Icon,
  Checkbox,
  Modal
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useRef, useState } from 'react';
import { CurrencyInput } from './CurrencyInput';
import {
  SubscriptionPlanPrice,
  SubscriptionPlanPriceDataInput,
  SubscriptionPlanPriceDataUpdateInput
} from '../generated/graphql';
import { getChanged, formatCurrency } from '../utils';
import { GridApi, DataGridPro, GridActionsCellItem } from '../vendor/x-grid';
import { ModalContent } from './ModalContent';
import { Center } from './Center';
import {
  SubscriptionPlanPriceForm,
  SubscriptionPlanPriceFormProps
} from './form';

export type SubscriptionPlanPricesProps = {
  prices: SubscriptionPlanPrice[];
  onAdd?: (data: SubscriptionPlanPriceDataInput) => Promise<void>;
  onUpdate?: (
    id: string,
    data: SubscriptionPlanPriceDataUpdateInput
  ) => Promise<void>;
};

type PlanPriceAddFormModalProps = { open: boolean } & Pick<
  SubscriptionPlanPriceFormProps,
  'onSave' | 'onCancel'
>;

const PlanPriceAddFormModal: FC<PlanPriceAddFormModalProps> = ({
  open,
  onSave,
  onCancel
}) => {
  return (
    <Modal open={open}>
      <ModalContent sx={{ p: 2, width: '35%' }}>
        <Center sx={{ mb: 2 }}>
          <Typography variant="h6">Add Plan Price</Typography>
        </Center>
        <SubscriptionPlanPriceForm onSave={onSave} onCancel={onCancel} />
      </ModalContent>
    </Modal>
  );
};

export const SubscriptionPlanPrices: FC<SubscriptionPlanPricesProps> = ({
  prices,
  onAdd,
  onUpdate
}) => {
  const apiRef = useRef<GridApi>({} as GridApi);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Paper
      sx={{
        pt: 1
      }}
      elevation={2}
    >
      <Box
        sx={{
          px: 2,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6">Prices</Typography>
        <IconButton onClick={() => setModalOpen(true)}>
          <Icon>add_box</Icon>
        </IconButton>
      </Box>
      <PlanPriceAddFormModal
        open={modalOpen}
        onSave={async data => {
          if (onAdd) {
            await onAdd(data);
          }

          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      />
      <DataGridPro
        apiRef={apiRef}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        columns={[
          {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            getActions: ({ id, row }) => {
              if (apiRef.current.getRowMode(id) === 'edit') {
                return [
                  <GridActionsCellItem
                    key={`${id}-done`}
                    icon={<Icon>done</Icon>}
                    label="Done"
                    onClick={async () => {
                      apiRef.current.commitRowChange(id);

                      if (onUpdate) {
                        await onUpdate(
                          String(id),
                          getChanged(
                            row,
                            apiRef.current.getRow(
                              id
                            ) as SubscriptionPlanPriceDataInput
                          )
                        );
                      }

                      apiRef.current.setRowMode(id, 'view');
                    }}
                  />
                ];
              }

              return [
                <GridActionsCellItem
                  key={`${id}-edit`}
                  icon={<Icon>edit</Icon>}
                  label="Edit"
                  onClick={() => {
                    apiRef.current.setRowMode(id, 'edit');
                  }}
                />
              ];
            }
          },
          {
            field: 'name',
            headerName: 'Name',
            editable: true,
            flex: 1
          },
          {
            field: 'price',
            headerName: 'Price',
            editable: true,
            flex: 1,
            renderCell: ({ value }) => {
              return `₹ ${formatCurrency(String(Number(value) / 100))}`;
            },
            renderEditCell: ({ value, api, id, field }) => {
              return (
                <CurrencyInput
                  id="price"
                  name="price"
                  fullWidth
                  variant="standard"
                  value={Number(value)}
                  onValueChange={v => {
                    api.setEditCellValue({
                      id,
                      field,
                      value: v
                    });
                  }}
                />
              );
            }
          },
          {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: true
          },
          {
            field: 'active',
            headerName: 'Active',
            flex: 1,
            editable: true,
            renderCell: ({ value }) => {
              return <Checkbox checked={Boolean(value)} />;
            },
            renderEditCell: ({ value, api, id, field }) => {
              const v = Boolean(value);

              return (
                <Checkbox
                  value={v}
                  checked={v}
                  onChange={() => {
                    api.setEditCellValue({
                      id,
                      field,
                      value: !v
                    });
                  }}
                />
              );
            }
          }
        ]}
        rows={prices}
        autoHeight
        editMode="row"
        disableSelectionOnClick
      />
    </Paper>
  );
};
