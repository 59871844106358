import { CircularProgress, Typography, Box } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  Center,
  Container,
  ContainerHeader,
  SubscriptionPlanForm,
  SubscriptionPlanFormProps
} from '../../components';
import {
  SubscriptionPlanQuery,
  useCreateSubscriptionPlanPriceMutation,
  useSubscriptionPlanQuery,
  useSubscriptionPlansQuery,
  useUpdateSubscriptionPlanMutation,
  useUpdateSubscriptionPlanPriceMutation
} from '../../generated/graphql';

const Content: FC<
  {
    loading: boolean;
    plan: SubscriptionPlanQuery['subscriptionPlan'] | null;
  } & Pick<
    SubscriptionPlanFormProps,
    'onPriceAdd' | 'onPriceUpdate' | 'onUpdate'
  >
> = ({ loading, plan, onUpdate, onPriceAdd, onPriceUpdate }) => {
  if (loading && !plan) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <SubscriptionPlanForm
        plan={plan ?? undefined}
        onUpdate={onUpdate}
        onPriceAdd={onPriceAdd}
        onPriceUpdate={onPriceUpdate}
      />
    </Box>
  );
};

export const SubscriptionPlan: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { data, isLoading: loading } = useSubscriptionPlanQuery(
    { id },
    { enabled: id !== '' }
  );
  const updateSubscriptionPlan = useUpdateSubscriptionPlanMutation({
    onSuccess: () => {
      queryClient.refetchQueries([
        useSubscriptionPlanQuery.getKey({ id }),
        useSubscriptionPlansQuery.getKey()
      ]);
    }
  });
  const createPlanPrice = useCreateSubscriptionPlanPriceMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useSubscriptionPlanQuery.getKey({ id }));
    }
  });
  const updatePlanPrice = useUpdateSubscriptionPlanPriceMutation();

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Subscription Plan ({id})</Typography>
      </ContainerHeader>
      <Content
        loading={loading}
        plan={data?.subscriptionPlan ?? null}
        onUpdate={async data => {
          await updateSubscriptionPlan.mutateAsync({ id, data });
        }}
        onPriceAdd={async price => {
          await createPlanPrice.mutateAsync({
            id: data?.subscriptionPlan?.id ?? '',
            data: price
          });
        }}
        onPriceUpdate={async (id, data) => {
          await updatePlanPrice.mutateAsync({ id, data });
        }}
      />
    </Container>
  );
};
