import { Box } from '@mui/system';
import React, { FC } from 'react';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { CardWithIcon } from '../components';
import { formatCurrency } from '../utils';

Chart.register(...registerables);

const options = {
  responsive: true,
  aspectRatio: 16 / 9,
  plugins: {
    legend: {
      position: 'top' as const
    }
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      label: 'Subscribers',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)'
    }
  ]
};

export const Dashboard: FC = () => {
  return (
    <Box display="flex" flex={1}>
      <Box display="flex" flex={2} flexDirection="column">
        <Box sx={{ display: 'flex', width: '100%', height: 'fit-content' }}>
          <CardWithIcon
            icon="attach_money"
            title="Monthly Revenue"
            subtitle={`$ ${formatCurrency('123456')}`}
          />
          <CardWithIcon
            icon="shopping_cart"
            title="New Subscribers"
            subtitle={`${formatCurrency('123456')}`}
          />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', height: 'fit-content' }}>
          <Box
            sx={{
              flex: 1,
              margin: 1
            }}
            flex={1}
          >
            <Line options={options} data={data} />
          </Box>
          <Box
            sx={{
              flex: 1,
              margin: 1
            }}
            flex={1}
          >
            <Line options={options} data={data} />
          </Box>
        </Box>
        <Box sx={{ height: 500 }}></Box>
      </Box>
    </Box>
  );
};
