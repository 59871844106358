import { applyDiff, getDiff } from 'recursive-diff';

export const isEmpty = (obj: any) => {
  if (obj === null || obj === undefined || obj === '') {
    return true;
  }

  return Object.keys(obj).length === 0;
};

export const getChanged = <T = any, U = any>(
  obj1: T,
  obj2: U,
  type: 'array' | 'obj' = 'obj'
): U => {
  if (type === 'obj') {
    return applyDiff({}, getDiff(obj1, obj2));
  }

  const diff = getDiff(obj1, obj2);

  if (diff.length === 0) {
    return [] as any as U;
  }

  return applyDiff(obj1, diff);
};

export const formatCurrency = (currency: string): string => {
  // eslint-disable-next-line prefer-const
  let [price, decimals] = currency.split('.');

  const getLen = (len: number, first = true): number[] => {
    const max = first ? 3 : 2;

    if (len > max) {
      return [max, ...getLen(len - max, false)];
    }

    return [len];
  };

  const lens = getLen(price.length);

  const figures = lens
    .reduce<string[]>((acc, l) => {
      const p = price.slice(-l);

      price = price.substring(0, price.length - l);

      return [p, ...acc];
    }, [])
    .join(',');

  if (decimals) {
    return `${figures}.${decimals}`;
  }

  return figures;
};

export const parseTime = (minutes: number) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;

  let time = '';

  if (hrs > 0) {
    time += `${hrs} hrs `;
  }
  time += `${mins < 10 ? `0${mins}` : mins} mins`;

  return time;
};

// validator.js - https://github.com/validatorjs/validator.js/blob/master/src/lib/isUUID.js
export const isUUID = (str: string) => {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
    str
  );
};
