import { Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { AppBar, SideDrawer } from './components';
import Routes from './Route';

export const Content: FC = () => {
  return (
    <>
      <AppBar />
      <SideDrawer />
      <Box display="flex" flexGrow={1} flexDirection="column">
        <Toolbar />
        <Box flex={1} padding={2}>
          <Routes />
        </Box>
      </Box>
    </>
  );
};
