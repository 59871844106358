import create from 'zustand';

export type ITitleStore = {
  title: string;
  setTitle: (title: string) => void;
};

export const useTitle = create<ITitleStore>(set => ({
  title: 'PurpledTV Admin',
  setTitle: (title: string) => {
    set(() => ({ title }));
  }
}));
