import { Modal, Typography, Divider } from '@mui/material';
import React, { FC } from 'react';
import { SeriesEpisodeDataInput, SeriesSeason } from '../generated/graphql';
import { ModalContent } from './ModalContent';
import { SeriesEpisodeForm } from './form';

export type SeriesEpisodeAddModalProps = {
  open: boolean;
  season: SeriesSeason;
  onSave?: (data: SeriesEpisodeDataInput) => Promise<void>;
  onCancel?: () => void;
};

export const SeriesEpisodeAddModal: FC<SeriesEpisodeAddModalProps> = ({
  open,
  season,
  onSave,
  onCancel
}) => {
  return (
    <Modal open={open}>
      <ModalContent sx={{ width: '45%', maxHeight: '95vh', overflow: 'auto' }}>
        <Typography variant="h5" sx={{ m: 2 }}>
          Add Episode
        </Typography>
        <Divider />
        <SeriesEpisodeForm
          season={season}
          onSave={onSave}
          onCancel={onCancel}
        />
      </ModalContent>
    </Modal>
  );
};
