import axios from 'axios';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BASE_API_URL } from './constants';
import { Page } from './Page';
import './main.css';

axios.defaults.baseURL = BASE_API_URL;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5
    }
  }
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
