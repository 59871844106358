import { LoadingButton } from '@mui/lab';
import { Button, Grid, Icon, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Center } from '../Center';
import {
  SeriesEpisodeDataInput,
  SeriesEpisodeDataUpdateInput,
  SeriesEpisode,
  SeriesSeason
} from '../../generated/graphql';
import { useFile } from '../../hooks';
import { getChanged } from '../../utils';

export type SeriesEpisodeFormProps = {
  data?: SeriesEpisode;
  season: SeriesSeason;
  onSave?: (data: SeriesEpisodeDataInput) => Promise<void>;
  onUpdate?: (data: SeriesEpisodeDataUpdateInput) => Promise<void>;
  onCancel?: () => void;
};

export const SeriesEpisodeForm: FC<SeriesEpisodeFormProps> = ({
  data,
  season,
  onSave,
  onUpdate,
  onCancel
}) => {
  const [editState] = useState(() => {
    if (onSave) return 'add' as const;
    if (onUpdate) return 'update' as const;
    return undefined;
  });
  const {
    file: thumbnail,
    url: thumbnailUrl,
    setFile: setThumbnail,
    upload
  } = useFile();
  const [changedState, setChangedState] =
    useState<SeriesEpisodeDataUpdateInput>({});
  const {
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik<SeriesEpisodeDataInput>({
    initialValues: {
      title: '',
      thumbnail: null,
      description: '',
      number: 0,
      mediaUrl: null
    },
    onSubmit: async data => {
      if (onSave || onUpdate) {
        const { location } = await upload();

        if (onSave) {
          await onSave({
            ...data,
            thumbnail: location
          });
        } else if (onUpdate) {
          await onUpdate({
            ...changedState,
            ...(location && { thumbnail: location })
          });
        }
      }
    }
  });

  useEffect(() => {
    setValues(c => ({
      ...c,
      ...data
    }));
  }, [data, setValues]);

  useEffect(() => {
    if (editState === 'update') {
      setChangedState(getChanged(data, values));
    }
  }, [data, editState, values]);

  return (
    <Grid
      component="form"
      container
      spacing={2}
      sx={{ p: 2 }}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} component={Center}>
        <Center
          sx={{
            width: 380,
            aspectRatio: '16 / 9',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'text.secondary',
            borderRadius: '5px'
          }}
        >
          {thumbnail || values.thumbnail ? (
            <Button
              sx={{ padding: 0, width: '100%', height: '100%' }}
              href="#!"
              LinkComponent="label"
              {...{ htmlFor: 'file-input' }}
            >
              <img
                src={thumbnailUrl ?? values.thumbnail ?? ''}
                alt="Thumbnail"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 5
                }}
              />
            </Button>
          ) : (
            <Button
              variant="outlined"
              LinkComponent="label"
              href="#!"
              {...{ htmlFor: 'file-input' }}
            >
              Pick Image
            </Button>
          )}
          <input
            id="file-input"
            type="file"
            style={{ display: 'none' }}
            onChange={e => {
              if (e.target.files) {
                if (e.target.files.length > 0) {
                  setThumbnail(e.target.files[0]);
                }
              }
            }}
          />
        </Center>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Number"
          fullWidth
          value={values.number}
          onChange={e => {
            const no = parseInt(e.target.value, 10);

            if (no) {
              setFieldValue('number', no);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Title"
          fullWidth
          value={values.title}
          onChange={handleChange('title')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={3}
          value={values.description}
          onChange={handleChange('description')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Media"
          fullWidth
          value={values.mediaUrl ?? ''}
          onChange={handleChange('mediaUrl')}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <LoadingButton
          loading={isSubmitting}
          disabled={isSubmitting}
          startIcon={<Icon>save</Icon>}
          variant="contained"
          type="submit"
        >
          Save
        </LoadingButton>
        {onCancel && (
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
