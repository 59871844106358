import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  Icon,
  Modal,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { Center } from '../../Center';
import { ModalContent } from '../../ModalContent';

export type TrailerInputProps = {
  videoId?: string;
  onSave?: (videoId: string) => Promise<void>;
};

type TrailerModalProps = TrailerInputProps & {
  open: boolean;
  onCancel?: () => void;
};

const TrailerModal: FC<TrailerModalProps> = ({
  open,
  onCancel,
  onSave,
  videoId
}) => {
  const [data, setData] = useState('');

  useEffect(() => {
    setData(videoId ?? '');
  }, [videoId]);

  return (
    <Modal open={open}>
      <ModalContent sx={{ p: 2, width: '45%' }}>
        <Typography variant="h5">Trailer</Typography>
        <Divider />
        <Center sx={{ m: 2 }}>
          <YoutubeIframe videoId={data} />
        </Center>
        <TextField
          fullWidth
          label="Video Id"
          variant="standard"
          value={data}
          onChange={e => {
            setData(e.target.value);
          }}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton
            variant="contained"
            onClick={async () => {
              if (onSave && data) {
                await onSave(data);
              }
            }}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </LoadingButton>
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const TrailerInput: FC<TrailerInputProps> = ({ videoId, onSave }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">Trailer</Typography>
        <IconButton
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Box>
      <TrailerModal
        open={modalOpen}
        videoId={videoId}
        onSave={async d => {
          if (onSave) {
            await onSave(d);
          }

          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      />
      <Divider />
      <Center sx={{ my: 1 }}>
        {!videoId && <Typography>No Trailer</Typography>}
        {videoId && <YoutubeIframe videoId={videoId} />}
      </Center>
    </Box>
  );
};

const YoutubeIframe: FC<{ videoId: string }> = ({ videoId }) => {
  return (
    <iframe
      title="YouTube video player"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      width={420}
      height={420 / 1.7777}
    />
  );
};
