import { Paper, PaperProps } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import React, { FC } from 'react';

export const Container: FC<PaperProps> = ({ children, sx, ...props }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        height: '100%',
        ...sx
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export const ContainerHeader: FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'text.secondary',
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
