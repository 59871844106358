import {
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import React, {
  FC,
  useCallback,
  useState,
  useRef,
  MutableRefObject
} from 'react';
import { useQueryClient } from 'react-query';
import {
  Center,
  Container,
  ContainerHeader,
  ModalContent
} from '../../components';
import {
  useCreateGenreMutation,
  useGenresQuery,
  useUpdateGenreMutation
} from '../../generated/graphql';
import { usePaginationState } from '../../hooks';
import { DataGridPro, GridApi } from '../../vendor/x-grid';

export const Genre: FC = () => {
  const { query, page, pageSize, setPage, setPageSize } = usePaginationState();
  const queryClient = useQueryClient();
  const { data, isLoading: loading } = useGenresQuery({ query });
  const createGenre = useCreateGenreMutation({
    onSuccess: () => {
      // TODO: Make GenresQuery take optional query.
      queryClient.refetchQueries([useGenresQuery.getKey({ query })[0]]);
    }
  });
  const updateGenre = useUpdateGenreMutation({});
  const apiRef = useRef<GridApi | null>(null);
  const [newTitle, setNewTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleOnSave = useCallback(async () => {
    await createGenre.mutateAsync({
      data: {
        title: newTitle
      }
    });

    setOpenModal(false);
  }, [createGenre, newTitle]);

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Genres</Typography>
        <IconButton
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Icon>add_box</Icon>
        </IconButton>
      </ContainerHeader>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalContent sx={{ p: 4, width: '30%' }}>
          <Center sx={{ mb: 3 }}>
            <Typography variant="h5">Add Genre</Typography>
          </Center>
          <TextField
            label="Title"
            fullWidth
            value={newTitle}
            onChange={e => {
              setNewTitle(e.target.value);
            }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleOnSave}>
              Save
            </Button>
          </Box>
        </ModalContent>
      </Modal>
      <DataGridPro
        apiRef={apiRef as MutableRefObject<GridApi>}
        columns={[
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            getActions: ({ id }) => {
              if (apiRef.current === null) {
                return [];
              }

              const current = apiRef.current;

              const isEditing = current.getRowMode(id) === 'edit';

              if (isEditing) {
                return [
                  <IconButton
                    key={`${id}-done`}
                    onClick={async () => {
                      current.commitRowChange(id);
                      const row = current.getRow(id);

                      if (row !== null) {
                        await updateGenre.mutateAsync({
                          id: row.id,
                          data: {
                            title: row.title
                          }
                        });
                      }

                      current.setRowMode(id, 'view');
                    }}
                  >
                    <Icon>done</Icon>
                  </IconButton>
                ];
              }

              return [
                <IconButton
                  key={`${id}-edit`}
                  onClick={() => {
                    apiRef.current?.setRowMode(id, 'edit');
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
              ];
            }
          },
          {
            field: 'title',
            headerName: 'Name',
            flex: 2,
            editable: true
          },
          {
            field: 'id',
            headerName: 'UUID',
            flex: 2
          }
        ]}
        rows={data?.genres?.items ?? []}
        disableSelectionOnClick
        editMode="row"
        pagination
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        rowCount={data?.genres?.count ?? 0}
        onPageChange={p => setPage(p)}
        onPageSizeChange={s => setPageSize(s)}
        loading={loading}
      />
    </Container>
  );
};
