import {
  Typography,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  TablePagination
} from '@mui/material';
import React, { FC, Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  Center,
  Container,
  ContainerHeader,
  TalkShowEpisodeAddModal,
  TalkShowEpisodeForm
} from '../../components';
import {
  useCreateTalkShowEpisodeMutation,
  useUpdateTalkShowEpisodeMutation,
  useTalkShowEpisodesQuery,
  useTalkShowQuery,
  TalkShowEpisode as IEpisode
} from '../../generated/graphql';
import { usePaginationState } from '../../hooks';

export const TalkShowEpisode: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { data } = useTalkShowQuery({ id }, { enabled: id !== '' });
  const show = data?.talkShow;

  const { page, pageSize, query, setPage } = usePaginationState();
  const { data: episodesRes } = useTalkShowEpisodesQuery(
    { showId: show?.id ?? '', query },
    { enabled: show?.id !== '' }
  );
  const episodes = episodesRes?.talkShowEpisodes;
  const createEpisode = useCreateTalkShowEpisodeMutation({
    onSuccess: () => {
      queryClient.refetchQueries(
        useTalkShowEpisodesQuery.getKey({ showId: show?.id ?? '' })
      );
    }
  });
  const updateEpisode = useUpdateTalkShowEpisodeMutation({
    onSuccess: () => {
      queryClient.refetchQueries(
        useTalkShowEpisodesQuery.getKey({ showId: show?.id ?? '' })
      );
    }
  });

  const [addModal, setAddModal] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState<IEpisode>();

  if (!show) {
    return null;
  }

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Talk Show ({id})</Typography>
      </ContainerHeader>
      <Box sx={{ display: 'flex', p: 1.5, flex: 1 }}>
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflowX: 'hidden'
          }}
        >
          <TalkShowEpisodeAddModal
            open={addModal}
            onCancel={() => setAddModal(false)}
            onSave={async data => {
              await createEpisode.mutateAsync({
                showId: show.id,
                data
              });

              setAddModal(false);
            }}
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setAddModal(true)}
          >
            Add Episode
          </Button>
          {episodes?.items.length !== 0 ? (
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <List component="nav">
                {episodes?.items?.map(e => {
                  return (
                    <Fragment key={e.id}>
                      <ListItemButton
                        selected={selectedEpisode?.id === e.id}
                        onClick={() => {
                          setSelectedEpisode(e);
                        }}
                      >
                        <ListItemText primary={`Episode ${e.number}`} />
                      </ListItemButton>
                    </Fragment>
                  );
                })}
              </List>
              <TablePagination
                component="div"
                count={episodes?.count ?? 0}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[pageSize]}
                onPageChange={(_, p) => {
                  setPage(p);
                }}
              />
            </Box>
          ) : (
            <Center sx={{ flex: 1 }}>
              <Typography>No Episodes</Typography>
            </Center>
          )}
        </Box>
        <Box sx={{ display: 'flex', flex: 1, ml: 2 }}>
          {selectedEpisode ? (
            <TalkShowEpisodeForm
              data={selectedEpisode}
              onUpdate={async data => {
                await updateEpisode.mutateAsync({
                  id: selectedEpisode.id,
                  data
                });
              }}
            />
          ) : (
            <Center flex={1}>
              <Typography>Select an Episode</Typography>
            </Center>
          )}
        </Box>
      </Box>
    </Container>
  );
};
