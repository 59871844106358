import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  Container,
  ContainerHeader,
  LatestList,
  SlideList,
  TrendingList
} from '../../components';

const usePage = () => {
  const { location, push } = useHistory();
  const page = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return params.get('page') || '';
  }, [location.search]);

  const setPage = useCallback(
    (name: string) => {
      if (page !== name) {
        push(`${location.pathname}?page=${name}`);
      }
    },
    [page, push, location.pathname]
  );

  return [page, setPage] as const;
};

const MobilePageSideList: FC = () => {
  const [page, setPage] = usePage();

  return (
    <Box sx={{ flex: 1 }}>
      <List
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          overflow: 'hidden'
        }}
        subheader={<ListSubheader>Settings</ListSubheader>}
      >
        <ListItemButton
          onClick={() => setPage('slide')}
          selected={page === 'slide'}
        >
          <ListItemText primary="Slide" />
        </ListItemButton>
        <ListItemButton
          onClick={() => setPage('latest')}
          selected={page === 'latest'}
        >
          <ListItemText primary="Latest" />
        </ListItemButton>
        <ListItemButton
          onClick={() => setPage('trending')}
          selected={page === 'trending'}
        >
          <ListItemText primary="Trending" />
        </ListItemButton>
      </List>
    </Box>
  );
};

const MobilePageContent: FC = () => {
  const [page] = usePage();
  const { replace, location } = useHistory();

  switch (page) {
    case 'latest':
      return <LatestList />;
    case 'trending':
      return <TrendingList />;
    case 'slide':
      return <SlideList />;
    default:
      const params = new URLSearchParams(location.search);
      params.set('page', 'slide');

      replace(`${location.pathname}?${params.toString()}`);

      return null;
  }
};

export const MobilePage: FC = () => {
  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Mobile</Typography>
      </ContainerHeader>
      <Box sx={{ display: 'flex', flex: 1, p: 2 }}>
        <MobilePageSideList />
        <Box sx={{ flex: 4, ml: 2 }}>
          <MobilePageContent />
        </Box>
      </Box>
    </Container>
  );
};
