import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BASE_API_URL } from '../constants';
import { fetchData, GqlError } from '../fetcher';
import {
  AuthLogInInput,
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  useLogInMutation,
  useLogOutMutation
} from '../generated/graphql';
import { useAdminQuery } from '../generated/graphql';
import { useAuthTokenStore } from '../store/auth';
import { useSetUser, useUserStore } from '../store/user';

export const useCheckAuth = (onCompleted: () => void) => {
  const jwt = useRef('');
  const refreshToken = useRef('');

  const [isLoadingTokens, setLoadingTokens] = useState(true);
  const { tokens, loadTokens, setTokens } = useAuthTokenStore(e => ({
    tokens: e.tokens,
    loadTokens: e.loadTokens,
    setTokens: e.setTokens
  }));
  const setUser = useUserStore(e => e.setUser);
  const {
    data: adminRes,
    error: adminError,
    refetch,
    remove
  } = useAdminQuery(undefined, {
    enabled: !!tokens?.jwt,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    axios.interceptors.request.use(config => {
      return {
        ...config,
        baseURL: BASE_API_URL,
        headers: {
          // Placed first so that it can be overridden. (eg. refresh token)
          ...(jwt.current && {
            authorization: `Bearer ${jwt.current}`
          }),
          ...config.headers
        }
      };
    });

    axios.interceptors.response.use<AxiosResponse<any, any>>(async res => {
      // @ts-expect-error
      if (res.config._retry) {
        return res;
      }

      const data = res.data as any;
      if (data.errors) {
        const err = new GqlError(data.errors);

        for (let e of err.gqlErrors) {
          if (e.extensions?.code === 'FORBIDDEN') {
            const { authTokenRefresh } = await fetchData<
              RefreshTokenMutation,
              RefreshTokenMutationVariables
            >(RefreshTokenDocument, {
              refreshToken: refreshToken.current
            })();

            setTokens(authTokenRefresh);

            // @ts-expect-error
            res.config._refresh = true;

            return axios({
              ...res.config,
              headers: {
                ...res.config.headers,
                authorization: `Bearer ${authTokenRefresh.jwt}`
              }
            });
          }
        }
      }

      return res;
    });
  }, [setTokens]);

  useEffect(() => {
    setLoadingTokens(true);
    loadTokens().then(tokens => {
      setTokens(tokens);
      setLoadingTokens(false);
    });
  }, [loadTokens, setTokens]);

  useEffect(() => {
    if (isLoadingTokens) return;

    if (tokens) {
      jwt.current = tokens.jwt;
      refreshToken.current = tokens.refreshToken;
      refetch();
    } else {
      jwt.current = '';
      refreshToken.current = '';
      setUser(null);
      remove();
      onCompleted();
    }
  }, [isLoadingTokens, tokens, onCompleted, setUser, refetch, remove]);

  useEffect(() => {
    if (adminRes?.admin) {
      setUser(adminRes.admin);
      onCompleted();
    }
  }, [adminRes, onCompleted, setUser]);

  useEffect(() => {
    if (adminError) {
      console.error(adminError);
      setUser(null);
      onCompleted();
    }
  }, [adminError, onCompleted, setUser]);
};

export const useLogIn = () => {
  const setToken = useAuthTokenStore(e => e.setTokens);
  const logIn = useLogInMutation();

  return useCallback(
    async (data: AuthLogInInput) => {
      try {
        const { authLogIn } = await logIn.mutateAsync({
          data: {
            ...data,
            aud: 'admin'
          }
        });

        if (!authLogIn) {
          return false;
        }

        await setToken(authLogIn);
        return true;
      } catch (e: any) {
        if (!e.response) {
          throw e;
        }
      }

      return false;
    },
    [logIn, setToken]
  );
};

export const useLogOut = () => {
  const setTokens = useAuthTokenStore(e => e.setTokens);
  const setUser = useSetUser();
  const logOut = useLogOutMutation({
    onSuccess: ({ authLogOut }) => {
      if (authLogOut) {
        setTokens(null);
        setUser(null);
      }
    },
    onError: err => {
      console.error(err);
    }
  });

  return () => logOut.mutateAsync({});
};
