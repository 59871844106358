import { Icon, IconButton } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { DataGridPro } from '../vendor/x-grid';
import {
  useRemoveSlideMutation,
  useSlidesQuery,
  useUpdateSlideMutation
} from '../generated/graphql';
import { usePaginationState } from '../hooks';
import { useQueryClient } from 'react-query';

export const SlideList: FC = () => {
  const queryClient = useQueryClient();
  const { query, pageSize, page, setPage, setPageSize } = usePaginationState();
  const { data, isLoading: loading } = useSlidesQuery({ query });

  const updateSlide = useUpdateSlideMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useSlidesQuery.getKey({ query }));
    }
  });
  const removeSlide = useRemoveSlideMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useSlidesQuery.getKey({ query }));
    }
  });

  const rows = useMemo(() => {
    return (
      data?.slide.items.map(l => {
        return {
          rank: l.rank,
          ...l.item,
          id: l.id
        };
      }) ?? []
    );
  }, [data?.slide.items]);

  return (
    <DataGridPro
      columns={[
        {
          field: 'title',
          headerName: 'Title',
          flex: 2
        },
        {
          field: 'rank',
          headerName: 'Rank',
          flex: 2,
          editable: true,
          type: 'number',
          align: 'left',
          headerAlign: 'left'
        },
        {
          field: 'action',
          headerName: 'Action',
          renderCell: ({ id }) => {
            return (
              <IconButton
                onClick={async () => {
                  await removeSlide.mutateAsync({
                    id: Number(id)
                  });
                }}
              >
                <Icon color="error">delete</Icon>
              </IconButton>
            );
          }
        }
      ]}
      rows={rows}
      disableSelectionOnClick
      pagination
      paginationMode="server"
      page={page}
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 20]}
      rowCount={data?.slide.count ?? 0}
      onPageChange={p => setPage(p)}
      onPageSizeChange={s => setPageSize(s)}
      loading={loading}
      onCellEditCommit={async ({ field, id, value }) => {
        await updateSlide.mutateAsync({
          id: Number(id),
          rank: Number(value)
        });
      }}
    />
  );
};
