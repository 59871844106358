import { LoadingButton } from '@mui/lab';
import {
  Chip,
  Typography,
  IconButton,
  Button,
  Icon,
  Divider,
  Modal,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TablePagination
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { ModalContent } from '../../ModalContent';
import { Center } from '../../Center';
import { Genre, useGenresQuery } from '../../../generated/graphql';
import { usePaginationState } from '../../../hooks';

export type GenreInputProps = {
  genres: Genre[];
  onChange?: (selected: Genre[]) => Promise<void>;
};

type GenreModalProps = {
  open: boolean;
  genres: Genre[];
  onSave?: GenreInputProps['onChange'];
  onCancel?: () => void;
};

const GenreModal: FC<GenreModalProps> = ({
  open,
  genres,
  onSave,
  onCancel
}) => {
  const { query, page, pageSize, setPage } = usePaginationState();
  const { data } = useGenresQuery({ query });
  const [selected, setSelected] = useState<Genre[]>([]);
  const [selectedGenres, setSelectedGenres] = useState<Set<string>>(new Set());

  useEffect(() => {
    setSelected(genres);
  }, [genres]);

  useEffect(() => {
    setSelectedGenres(new Set(selected.map(g => g.id)));
  }, [selected]);

  return (
    <Modal open={open}>
      <ModalContent
        sx={{
          p: 2,
          width: '45%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h5">Genres</Typography>
        <Divider />
        <Box sx={{ overflowY: 'auto', flexGrow: 1, my: 1.5 }}>
          <Box sx={{ mt: 1, mb: 2, py: 1 }}>
            {selected.length === 0 && <Center>No Genre Selected</Center>}
            {selected.map(g => (
              <Chip
                key={g.id}
                label={g.title}
                sx={{ margin: 0.2 }}
                color="info"
                variant="outlined"
                onDelete={() => {
                  setSelected(selected.filter(s => s.id !== g.id));
                }}
              />
            ))}
          </Box>
          <Divider />
          {data?.genres.items
            .filter(g => !selectedGenres.has(g.id))
            .map((g, idx) => (
              <React.Fragment key={g.id}>
                <ListItemButton
                  onClick={() => {
                    setSelected([...selected, g]);
                  }}
                >
                  <ListItemText primary={g.title} />
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Icon>add</Icon>
                  </ListItemIcon>
                </ListItemButton>
                {genres.length - 1 !== idx && <Divider />}
              </React.Fragment>
            ))}
          <TablePagination
            component="div"
            count={data?.genres.count ?? 0}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            onPageChange={(_, p) => {
              setPage(p);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            onClick={async () => {
              if (onSave) {
                await onSave(selected);
              }
            }}
          >
            Save
          </LoadingButton>
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const GenreInput: FC<GenreInputProps> = ({ genres, onChange }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">Genres</Typography>
        <IconButton
          size="small"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Box>
      <GenreModal
        open={modalOpen}
        genres={genres}
        onSave={async data => {
          if (onChange) {
            await onChange(data);
          }

          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      />
      <Divider sx={{ mb: 1 }} />
      <Box>
        {genres.length === 0 && (
          <Center sx={{ p: 2 }}>
            <Typography variant="body1">No Genres</Typography>
          </Center>
        )}
        {genres.map(g => (
          <Chip
            key={g.id}
            label={g.title}
            variant="outlined"
            color="info"
            clickable
            sx={{ margin: 0.2 }}
          />
        ))}
      </Box>
    </Box>
  );
};
