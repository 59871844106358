import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const ModalContent = styled(Paper)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&:focus': {
    outline: 'none'
  }
}));
