import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from '@mui/material';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { CurrencyInput } from '../CurrencyInput';
import { SubscriptionPlanPriceDataInput } from '../../generated/graphql';

export type SubscriptionPlanPriceFormProps = {
  onSave?: (data: SubscriptionPlanPriceDataInput) => Promise<void>;
  onCancel?: () => void;
};

export const SubscriptionPlanPriceForm: FC<SubscriptionPlanPriceFormProps> = ({
  onSave,
  onCancel
}) => {
  const {
    values,
    isSubmitting,
    handleChange,
    resetForm,
    setValues,
    handleSubmit
  } = useFormik<SubscriptionPlanPriceDataInput>({
    initialValues: {
      price: 0,
      name: '',
      time: '1M',
      active: false
    },
    onSubmit: async data => {
      if (onSave) {
        await onSave(data);
        resetForm();
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="standard"
            value={values.name}
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyInput
            name="price"
            label="Price"
            prefix="&#8377;&nbsp;"
            fullWidth
            variant="standard"
            value={Number(values.price)}
            onValueChange={v => {
              setValues(c => ({
                ...c,
                price: v
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="time"
            label="Time"
            fullWidth
            variant="standard"
            value={values.time}
            onChange={handleChange('time')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            name="active"
            label="Active"
            control={<Checkbox checked={values.active} />}
            value={values.active}
            onChange={handleChange('active')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
