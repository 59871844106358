import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { FC, useCallback, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Content } from './Content';
import { Loading, LogIn } from './pages';
import { useCheckAuth } from './service/auth';
import { useVerifyLoggedIn } from './store/auth';

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

export const Root: FC<{ loading: boolean; loggedIn: boolean }> = ({
  loading,
  loggedIn
}) => {
  if (loading) {
    return <Loading />;
  }

  if (loggedIn) {
    return <Content />;
  }

  return <LogIn />;
};

export const Page: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const isLoggedIn = useVerifyLoggedIn();
  const onAuthCheckComplete = useCallback(() => {
    setLoading(false);
  }, []);

  useCheckAuth(onAuthCheckComplete);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CssBaseline />
          <Root loading={isLoading} loggedIn={isLoggedIn} />
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  );
};
