import create from 'zustand';

export type IToken = {
  jwt: string;
  refreshToken: string;
};

export type ITokenStore = {
  tokens: IToken | null;
  setTokens: (token: IToken | null) => Promise<void>;
  loadTokens: () => Promise<IToken | null>;
};

const ACCESS_TOKEN_KEY = 'auth@access_token$key';
const REFRESH_TOKEN_KEY = 'auth@refresh_token$key';

export const useAuthTokenStore = create<ITokenStore>(set => ({
  tokens: null,
  setTokens: async (tokens: IToken | null) => {
    if (tokens) {
      window.localStorage.setItem(ACCESS_TOKEN_KEY, tokens.jwt);
      window.localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
    } else {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    set(() => ({ tokens }));
  },
  loadTokens: async () => {
    const jwt = window.localStorage.getItem(ACCESS_TOKEN_KEY) ?? null;
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY) ?? null;

    if (jwt && refreshToken) {
      return {
        jwt,
        refreshToken
      };
    }

    return null;
  }
}));
