import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  Icon,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import React, { FC, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Center,
  GenreInput,
  CastInput,
  Casts,
  TrailerInput,
  PriceInput,
  ReleasedDateInput
} from '../../components';
import {
  SeriesDataInput,
  SeriesDataUpdateInput,
  Genre,
  SeriesQuery
} from '../../generated/graphql';
import { useFile } from '../../hooks';
import { getChanged, isEmpty } from '../../utils';

export type ISeriesType = SeriesQuery['series'];

export type SeriesFormProps = {
  series?: ISeriesType;
  onSave?: (seriesData: SeriesDataInput) => Promise<void>;
  onUpdate?: (seriesData: SeriesDataUpdateInput) => Promise<void>;
};

export const SeriesForm: FC<SeriesFormProps> = ({
  series,
  onSave,
  onUpdate
}) => {
  const [editState] = useState(() => {
    if (onSave) return 'add' as const;
    if (onUpdate) return 'update' as const;
    return undefined;
  });
  const {
    file: thumbnail,
    url: thumbnailUrl,
    setFile: setThumbnail,
    upload: uploadThumbnail
  } = useFile();
  const {
    file: poster,
    url: posterUrl,
    setFile: setPoster,
    upload: uploadPoster
  } = useFile();

  const [changedState, setChangedState] = useState<SeriesDataUpdateInput>({});
  const {
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik<SeriesDataInput>({
    initialValues: {
      title: '',
      description: null,
      thumbnail: null,
      artists: [],
      genres: [],
      poster: null,
      price: null,
      releasedDate: null,
      trailer: null
    },
    onSubmit: async data => {
      if (onSave || onUpdate) {
        const [{ location: thumbnailLoc }, { location: posterLoc }] =
          await Promise.all([uploadThumbnail(), uploadPoster()]);

        if (editState === 'add') {
          if (onSave) {
            await onSave({
              ...data,
              thumbnail: thumbnailLoc ?? null,
              poster: posterLoc ?? null
            });
          }
        } else if (editState === 'update') {
          if (onUpdate) {
            await onUpdate({
              ...changedState,
              ...(thumbnailLoc && { thumbnail: thumbnailLoc }),
              ...(posterLoc && { poster: posterLoc })
            });
          }
        }
      }
    }
  });
  const [genres, setGenres] = useState<Genre[]>([]);
  const [casts, setCasts] = useState<Casts>([]);
  const [releasedDate, setReleasedDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (series) {
      setValues({
        ...series,
        thumbnail: series.thumbnail ?? null,
        genres: [],
        artists: []
      });
      setGenres(series.genres);
      setCasts(series.artists);
      if (series.releasedDate) {
        setReleasedDate(moment(series.releasedDate));
      }
    }
  }, [series, setValues]);

  useEffect(() => {
    setFieldValue(
      'genres',
      genres.map(g => g.id)
    );
  }, [genres, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'artists',
      casts.map(c => c.id)
    );
  }, [casts, setFieldValue]);

  useEffect(() => {
    if (releasedDate?.isValid()) {
      setFieldValue('releasedDate', releasedDate);
    }
  }, [releasedDate, setFieldValue]);

  useEffect(() => {
    if (editState === 'update') {
      const allChanged = getChanged<
        SeriesDataUpdateInput,
        SeriesDataUpdateInput
      >(
        {
          ...series,
          genres: undefined,
          artists: undefined,
          releasedDate: null
        },
        {
          ...values,
          genres: undefined,
          artists: undefined,
          releasedDate: null
        }
      );

      const genresChanged = getChanged(
        series?.genres.map(g => g.id),
        values.genres,
        'array'
      );
      if (!isEmpty(genresChanged)) {
        allChanged.genres = genresChanged;
      }

      const artistsChanged = getChanged(
        series?.artists.map(a => a.id),
        values.artists,
        'array'
      );
      if (!isEmpty(artistsChanged)) {
        allChanged.artists = artistsChanged;
      }

      setChangedState(allChanged);
    }
  }, [editState, series, values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          display="flex"
          xs={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
        >
          <Center
            sx={{
              width: 320 / 1.7,
              aspectRatio: '3 / 4',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'text.secondary',
              borderRadius: '5px'
            }}
          >
            {thumbnail || values.thumbnail ? (
              <Button
                sx={{ padding: 0, height: '100%' }}
                href="#!"
                LinkComponent="label"
                {...{ htmlFor: 'file-input' }}
              >
                <img
                  src={thumbnailUrl ?? values.thumbnail ?? ''}
                  alt="Thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 5
                  }}
                />
              </Button>
            ) : (
              <Button
                variant="outlined"
                LinkComponent="label"
                href="#!"
                {...{ htmlFor: 'file-input' }}
              >
                Pick Image
              </Button>
            )}
            <input
              id="file-input"
              type="file"
              style={{ display: 'none' }}
              onChange={e => {
                if (e.target.files) {
                  if (e.target.files.length > 0) {
                    setThumbnail(e.target.files[0]);
                  }
                }
              }}
            />
          </Center>
          <Center
            sx={{
              width: 320 * 1.4,
              aspectRatio: '16 / 9',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'text.secondary',
              borderRadius: '5px'
            }}
          >
            {poster || values.poster ? (
              <Button
                sx={{ padding: 0, height: '100%' }}
                href="#!"
                LinkComponent="label"
                {...{ htmlFor: 'file-input-poster' }}
              >
                <img
                  src={posterUrl ?? values.poster ?? ''}
                  alt="Thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 5
                  }}
                />
              </Button>
            ) : (
              <Button
                variant="outlined"
                LinkComponent="label"
                href="#!"
                {...{ htmlFor: 'file-input-poster' }}
              >
                Pick Image
              </Button>
            )}
            <input
              id="file-input-poster"
              type="file"
              style={{ display: 'none' }}
              onChange={e => {
                if (e.target.files) {
                  if (e.target.files.length > 0) {
                    setPoster(e.target.files[0]);
                  }
                }
              }}
            />
          </Center>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="title"
            label="Title"
            fullWidth
            value={values.title}
            onChange={handleChange('title')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={values.description ?? ''}
            onChange={handleChange('description')}
          />
        </Grid>
        <Grid item xs={12}>
          <TrailerInput
            videoId={values.trailer ?? undefined}
            onSave={async videoId => {
              setFieldValue('trailer', videoId);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <GenreInput
            genres={genres}
            onChange={async selected => {
              setGenres(selected);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CastInput
            casts={casts}
            onSave={async selected => {
              setCasts(selected);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PriceInput
            price={values.price ?? null}
            onSave={async p => {
              setFieldValue('price', p);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ReleasedDateInput
            releasedDate={values.releasedDate ?? null}
            onSave={async rd => {
              setFieldValue('releasedDate', rd);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Link
              component={RouterLink}
              to={`/series/${series?.id ?? ''}/episodes`}
            >
              <Typography variant="subtitle1">Seasons & Episodes</Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
