import axios from 'axios';
import { GraphQLError } from 'graphql';

const generateErrorMessage = (err: ReadonlyArray<GraphQLError>) => {
  return err
    .reduce<string[]>((acc, curr) => {
      acc.push(curr.message);

      return acc;
    }, [])
    .join('\n');
};

export class GqlError extends Error {
  public readonly gqlErrors: ReadonlyArray<GraphQLError>;

  constructor(graphQLErrors: ReadonlyArray<GraphQLError>) {
    super(generateErrorMessage(graphQLErrors));

    this.gqlErrors = graphQLErrors;
  }
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const res = await axios.post(
      '/graphql',
      {
        query,
        variables
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    const data = res.data as any;

    if (data.errors) {
      throw new GqlError(data.errors);
    }

    return data.data;
  };
};
