import { Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { Container, ContainerHeader } from '../../components';
import { PaginationQuery, useUsersQuery } from '../../generated/graphql';
import { DataGridPro, GridColumns } from '../../vendor/x-grid';

const COLUMNS: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 170
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1
  }
].map(a => ({ ...a, sortable: false }));

const usePaginationState = (): [
  PaginationQuery,
  number,
  number,
  React.Dispatch<React.SetStateAction<number>>,
  React.Dispatch<React.SetStateAction<number>>
] => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  return [
    {
      take: pageSize,
      skip: page * pageSize
    },
    page,
    pageSize,
    setPage,
    setPageSize
  ];
};

export const UserList: FC = () => {
  const [query, page, pageSize, setPage, setPageSize] = usePaginationState();
  const { data, isLoading } = useUsersQuery({ query });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Artists</Typography>
      </ContainerHeader>
      <DataGridPro
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        columns={COLUMNS}
        rows={data?.users.items ?? []}
        rowHeight={60}
        disableSelectionOnClick
        pagination
        paginationMode="server"
        disableColumnFilter
        disableColumnMenu
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[20, 50, 70]}
        rowCount={data?.users?.count ?? 0}
        loading={isLoading}
        onPageChange={p => {
          setPage(p);
        }}
        onPageSizeChange={s => {
          setPageSize(s);
        }}
      />
    </Container>
  );
};
