import { LoadingButton } from '@mui/lab';
import { Button, Grid, Icon, TextField } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { Moment } from 'moment';
import React, { FC, useState, useEffect } from 'react';
import {
  Center,
  GenreInput,
  CastInput,
  Casts,
  TrailerInput,
  ReleasedDateInput,
  RunningTimeInput,
  MediaInput,
  RecommendationInput
} from '../../components';
import {
  MusicDataInput,
  Genre,
  MusicDataUpdateInput,
  MusicQuery
} from '../../generated/graphql';
import { useFile } from '../../hooks';
import { getChanged, isEmpty } from '../../utils';

export type IMusicType = MusicQuery['music'];

export type MusicFormProps = {
  music?: IMusicType;
  onSave?: (musicData: MusicDataInput) => Promise<void>;
  onUpdate?: (musicData: MusicDataUpdateInput) => Promise<void>;
};

export const MusicForm: FC<MusicFormProps> = ({ music, onSave, onUpdate }) => {
  const [editState] = useState(() => {
    if (onSave) return 'add' as const;
    if (onUpdate) return 'update' as const;
    return undefined;
  });
  const {
    file: thumbnail,
    url: thumbnailUrl,
    setFile: setThumbnail,
    upload: uploadThumbnail
  } = useFile();
  const {
    file: poster,
    url: posterUrl,
    setFile: setPoster,
    upload: uploadPoster
  } = useFile();

  const [changedState, setChangedState] = useState<MusicDataUpdateInput>({});
  const {
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik<MusicDataInput>({
    initialValues: {
      title: '',
      description: '',
      thumbnail: '',
      artists: [],
      genres: [],
      recommendations: [],
      releasedDate: null,
      runningTime: null,
      trailer: null,
      mediaUrl: null
    },
    onSubmit: async data => {
      if (onSave || onUpdate) {
        const [{ location: thumbnailLoc }, { location: posterLoc }] =
          await Promise.all([uploadThumbnail(), uploadPoster()]);

        if (editState === 'add') {
          if (onSave) {
            await onSave({
              ...data,
              thumbnail: thumbnailLoc ?? null,
              poster: posterLoc ?? null
            });
          }
        } else if (editState === 'update') {
          if (onUpdate) {
            await onUpdate({
              ...changedState,
              ...(thumbnailLoc && { thumbnail: thumbnailLoc }),
              ...(posterLoc && { poster: posterLoc })
            });
          }
        }
      }
    }
  });
  const [genres, setGenres] = useState<Genre[]>([]);
  const [casts, setCasts] = useState<Casts>([]);
  const [recommendations, setRecommendations] = useState<
    NonNullable<MusicQuery['music']>['recommendations']
  >([]);
  const [releasedDate, setReleasedDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (music) {
      const { media, ...data } = music;
      setValues({
        ...data,
        thumbnail: music.thumbnail ?? null,
        genres: [],
        artists: [],
        recommendations: [],
        mediaUrl: media.url
      });
      setGenres(music.genres);
      setCasts(music.artists);
      setRecommendations(music.recommendations);
      if (music.releasedDate) {
        setReleasedDate(moment(music.releasedDate));
      }
    }
  }, [music, setValues]);

  useEffect(() => {
    setFieldValue(
      'genres',
      genres.map(g => g.id)
    );
  }, [genres, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'artists',
      casts.map(c => c.id)
    );
  }, [casts, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'recommendations',
      recommendations.map(r => r.id)
    );
  }, [recommendations, setFieldValue]);

  useEffect(() => {
    if (releasedDate?.isValid()) {
      setFieldValue('releasedDate', releasedDate);
    }
  }, [releasedDate, setFieldValue]);

  useEffect(() => {
    if (editState === 'update') {
      if (music) {
        const { media, ...data } = music;
        const allChanged = getChanged<
          MusicDataUpdateInput,
          MusicDataUpdateInput
        >(
          {
            ...data,
            genres: undefined,
            artists: undefined,
            recommendations: undefined,
            mediaUrl: media.url
          },
          {
            ...values,
            genres: undefined,
            artists: undefined,
            recommendations: undefined
          }
        );

        const genresChanged = getChanged(
          music?.genres.map(g => g.id),
          values.genres,
          'array'
        );
        if (!isEmpty(genresChanged)) {
          allChanged.genres = genresChanged;
        }

        const artistsChanged = getChanged(
          music?.artists.map(a => a.id),
          values.artists,
          'array'
        );
        if (!isEmpty(artistsChanged)) {
          allChanged.artists = artistsChanged;
        }

        const recommendationsChanged = getChanged(
          music?.recommendations.map(r => r.id),
          values.recommendations,
          'array'
        );
        if (!isEmpty(recommendationsChanged)) {
          allChanged.recommendations = recommendationsChanged;
        }

        setChangedState(allChanged);
      }
    }
  }, [editState, music, values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          display="flex"
          xs={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
        >
          <Center
            sx={{
              width: 320 / 1.7,
              aspectRatio: '3 / 4',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'text.secondary',
              borderRadius: '5px'
            }}
          >
            {thumbnail || values.thumbnail ? (
              <Button
                sx={{ padding: 0, height: '100%' }}
                href="#!"
                LinkComponent="label"
                {...{ htmlFor: 'file-input' }}
              >
                <img
                  src={thumbnailUrl ?? values.thumbnail ?? ''}
                  alt="Thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 5
                  }}
                />
              </Button>
            ) : (
              <Button
                variant="outlined"
                LinkComponent="label"
                href="#!"
                {...{ htmlFor: 'file-input' }}
              >
                Pick Image
              </Button>
            )}
            <input
              id="file-input"
              type="file"
              style={{ display: 'none' }}
              onChange={e => {
                if (e.target.files) {
                  if (e.target.files.length > 0) {
                    setThumbnail(e.target.files[0]);
                  }
                }
              }}
            />
          </Center>
          <Center
            sx={{
              width: 320 * 1.4,
              aspectRatio: '16 / 9',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'text.secondary',
              borderRadius: '5px'
            }}
          >
            {poster || values.poster ? (
              <Button
                sx={{ padding: 0, height: '100%' }}
                href="#!"
                LinkComponent="label"
                {...{ htmlFor: 'file-input-poster' }}
              >
                <img
                  src={posterUrl ?? values.poster ?? ''}
                  alt="Thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 5
                  }}
                />
              </Button>
            ) : (
              <Button
                variant="outlined"
                LinkComponent="label"
                href="#!"
                {...{ htmlFor: 'file-input-poster' }}
              >
                Pick Image
              </Button>
            )}
            <input
              id="file-input-poster"
              type="file"
              style={{ display: 'none' }}
              onChange={e => {
                if (e.target.files) {
                  if (e.target.files.length > 0) {
                    setPoster(e.target.files[0]);
                  }
                }
              }}
            />
          </Center>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="title"
            label="Title"
            fullWidth
            value={values.title}
            onChange={handleChange('title')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            multiline
            minRows={4}
            fullWidth
            value={values.description}
            onChange={handleChange('description')}
          />
        </Grid>
        <Grid item xs={12}>
          <TrailerInput
            videoId={values.trailer ?? undefined}
            onSave={async videoId => {
              setFieldValue('trailer', videoId);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <GenreInput
            genres={genres}
            onChange={async selected => {
              setGenres(selected);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CastInput
            casts={casts}
            onSave={async selected => {
              setCasts(selected);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RecommendationInput
            type="music"
            recommendations={recommendations}
            onSave={async r => {
              setRecommendations(r);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ReleasedDateInput
            releasedDate={values.releasedDate ?? null}
            onSave={async rd => {
              setFieldValue('releasedDate', rd);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RunningTimeInput
            runningTime={values.runningTime ?? null}
            onSave={async rt => {
              setFieldValue('runningTime', rt);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MediaInput
            mediaUrl={values.mediaUrl ?? null}
            onSave={async url => {
              setFieldValue('mediaUrl', url);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
