import { Box, CircularProgress, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Container, ContainerHeader, ArtistForm } from '../../components';
import {
  ArtistDataInput,
  ArtistDataUpdateInput,
  Maybe,
  useArtistQuery,
  useUpdateArtistMutation
} from '../../generated/graphql';

const Content: FC<{
  loading: boolean;
  artist?: Maybe<ArtistDataInput>;
  onSave?: (data: ArtistDataUpdateInput | undefined) => Promise<void>;
}> = ({ loading, artist, onSave }) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!artist) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        }}
      >
        <Typography variant="body1">Could not find Artist!</Typography>
      </Box>
    );
  }

  return <ArtistForm data={artist ?? undefined} onUpdate={onSave} />;
};

export const Artist: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading } = useArtistQuery({ id }, { enabled: !!id });
  const updateArtist = useUpdateArtistMutation();

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Artist ({id})</Typography>
      </ContainerHeader>
      <Box
        sx={{
          py: 2,
          px: 5,
          display: 'flex',
          flex: 1
        }}
      >
        <Content
          loading={isLoading}
          artist={data?.artist}
          onSave={async newData => {
            await updateArtist.mutateAsync({ id, data: newData ?? {} });
          }}
        />
      </Box>
    </Container>
  );
};
