import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Container, ContainerHeader, SeriesForm } from '../../components';
import {
  useCreateSeriesMutation,
  useSeriesAllQuery
} from '../../generated/graphql';

export const SeriesAdd: FC = () => {
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  const createSeries = useCreateSeriesMutation({
    onSuccess: ({ createSeries: { id } }) => {
      queryClient.invalidateQueries(
        // TODO: Make SeriesAllQuery variable optional
        [useSeriesAllQuery.getKey({ query: { take: 20, skip: 0 } })[0]]
      );
      replace(`/series/${id}`);
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Add Series</Typography>
      </ContainerHeader>
      <Box sx={{ p: 2 }}>
        <SeriesForm
          onSave={async data => {
            await createSeries.mutateAsync({
              data
            });
          }}
        />
      </Box>
    </Container>
  );
};
