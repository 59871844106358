import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Container, ContainerHeader, TalkShowForm } from '../../components';
import {
  useCreateTalkShowMutation,
  useTalkShowsQuery
} from '../../generated/graphql';

export const TalkShowAdd: FC = () => {
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  const createTalkShow = useCreateTalkShowMutation({
    onSuccess: ({ createTalkShow: { id } }) => {
      queryClient.invalidateQueries(useTalkShowsQuery.getKey());
      replace(`/show/${id}`);
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Add Talk Show</Typography>
      </ContainerHeader>
      <Box sx={{ p: 2 }}>
        <TalkShowForm
          onSave={async data => {
            await createTalkShow.mutateAsync({
              data
            });
          }}
        />
      </Box>
    </Container>
  );
};
