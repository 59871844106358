import { TextField, TextFieldProps } from '@mui/material';
import React, { FC, forwardRef } from 'react';
import CurrencyInputBase, {
  CurrencyInputProps
} from 'react-currency-input-field';

const TextFieldForward = forwardRef((props, ref) => {
  return <TextField inputRef={ref} {...props} />;
});

export const CurrencyInput: FC<
  Omit<CurrencyInputProps, 'value' | 'onValueChange'> &
    TextFieldProps & {
      value: number;
      onValueChange: (value: number) => void;
    }
> = ({ value, onValueChange, ...props }) => {
  return (
    <CurrencyInputBase
      customInput={TextFieldForward}
      intlConfig={{ locale: 'en-IN', currency: 'INR' }}
      value={value / 100}
      onValueChange={a => {
        onValueChange(Number(a ?? 0) * 100);
      }}
      {...props}
    />
  );
};
