import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const InfoInputContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  '& > .action': {
    opacity: 0,
    transition: 'all 0.3s ease-in-out'
  },
  '&:hover > .action': {
    opacity: 1
  }
});
