import {
  Divider,
  Icon,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';

export type MediaInputProps = {
  mediaUrl: string | null;
  onSave?: (mediaUrl: string | null) => Promise<void>;
};

export const MediaInput: FC<MediaInputProps> = ({ mediaUrl, onSave }) => {
  const [value, setValue] = useState(mediaUrl ?? '');

  useEffect(() => {
    setValue(mediaUrl ?? '');
  }, [mediaUrl]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">Media</Typography>
      </Box>
      <Divider />
      <Box sx={{ my: 1, display: 'flex' }}>
        <TextField
          fullWidth
          variant="standard"
          placeholder="Media Url"
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <IconButton
          disabled={(mediaUrl ?? '') === value}
          onClick={async () => {
            if (onSave) {
              await onSave(value);
            }
          }}
        >
          <Icon>done</Icon>
        </IconButton>
      </Box>
    </Box>
  );
};
