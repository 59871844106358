import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  );
};
