import { RouteComponentProps } from 'react-router-dom';
import {
  Artist,
  ArtistAdd,
  ArtistList,
  Dashboard,
  Genre,
  MobilePage,
  Movie,
  MovieAdd,
  MovieList,
  Music,
  MusicAdd,
  MusicList,
  Series,
  SeriesAdd,
  SeriesEpisode,
  SeriesList,
  SubscriptionPlan,
  SubscriptionPlanAdd,
  SubscriptionPlanList,
  TalkShow,
  TalkShowAdd,
  TalkShowEpisode,
  TalkShowList
} from './pages';
import { UserList } from './pages/user';

export type IRoute = {
  path: string;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  children?: IRoute[];
};

const ROUTES: IRoute = {
  path: '/',
  component: Dashboard,
  children: [
    {
      path: '/users',
      component: UserList
    },
    {
      path: '/artist',
      children: [
        {
          path: '/list',
          component: ArtistList
        },
        {
          path: '/add',
          component: ArtistAdd
        },
        {
          path: '/:id',
          component: Artist
        }
      ]
    },
    {
      path: '/genre',
      component: Genre
    },
    {
      path: '/subscription/plan',
      children: [
        {
          path: '/list',
          component: SubscriptionPlanList
        },
        {
          path: '/add',
          component: SubscriptionPlanAdd
        },
        {
          path: '/:id',
          component: SubscriptionPlan
        }
      ]
    },
    {
      path: '/movie',
      children: [
        {
          path: '/list',
          component: MovieList
        },
        {
          path: '/add',
          component: MovieAdd
        },
        {
          path: '/:id',
          component: Movie
        }
      ]
    },
    {
      path: '/music',
      children: [
        {
          path: '/list',
          component: MusicList
        },
        {
          path: '/add',
          component: MusicAdd
        },
        {
          path: '/:id',
          component: Music
        }
      ]
    },
    {
      path: '/series',
      children: [
        {
          path: '/list',
          component: SeriesList
        },
        {
          path: '/add',
          component: SeriesAdd
        },
        {
          path: '/:id',
          component: Series
        },
        {
          path: '/:id/episodes',
          component: SeriesEpisode
        }
      ]
    },
    {
      path: '/show',
      children: [
        {
          path: '/list',
          component: TalkShowList
        },
        {
          path: '/add',
          component: TalkShowAdd
        },
        {
          path: '/:id',
          component: TalkShow
        },
        {
          path: '/:id/episodes',
          component: TalkShowEpisode
        }
      ]
    },
    {
      path: '/mobile',
      component: MobilePage
    }
  ]
};

export default ROUTES;
