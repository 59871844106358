import { TextField, Typography, IconButton, Icon } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import TimeField from 'react-simple-timefield';
import { parseTime } from '../../../utils';
import { InfoInputContainer } from '../../InfoInputContainer';

export type RunningTimeProps = {
  runningTime: number | null;
  onSave?: (runningTime: number | null) => Promise<void>;
};

export const RunningTimeInput: FC<RunningTimeProps> = ({
  runningTime,
  onSave
}) => {
  const [state, setState] = useState<'edit' | 'view'>('view');
  const [value, setValue] = useState<number | null>(null);
  const [timeEdit, setTimeEdit] = useState<string>();

  useEffect(() => {
    setValue(runningTime);
  }, [runningTime]);

  useEffect(() => {
    const time = runningTime ?? 0;
    const hrs = Math.floor(time / 60);
    const mins = time % 60;

    let timeStr = '';

    if (hrs < 10) {
      timeStr += '0';
    }
    timeStr += hrs;

    timeStr += ':';

    if (mins < 10) {
      timeStr += '0';
    }
    timeStr += mins;

    if (timeStr !== '00:00') {
      setTimeEdit(timeStr);
    }
  }, [runningTime]);

  useEffect(() => {
    setValue(() => {
      const [hrs, mins] = (timeEdit ?? '00:00').split(':').map(Number);
      const time = hrs * 60 + mins;

      return time;
    });
  }, [timeEdit]);

  return (
    <InfoInputContainer>
      {state === 'view' ? (
        <>
          <Typography variant="subtitle1" sx={{ mr: 3, fontWeight: 'bold' }}>
            Running Time
          </Typography>
          <Typography sx={{ fontStyle: 'italic' }}>
            {runningTime ? parseTime(runningTime) : 'N/A'}
          </Typography>
        </>
      ) : (
        <TimeField
          value={timeEdit}
          input={
            <TextField
              fullWidth
              label="Running Time"
              variant="standard"
              value={value ?? 0}
            />
          }
          onChange={(_, value) => {
            setTimeEdit(value);
          }}
          colon=":"
        />
      )}

      <IconButton
        className={state === 'view' ? 'action' : ''}
        size="small"
        sx={{ ml: 1 }}
        onClick={async () => {
          if (state === 'edit') {
            if (onSave) {
              await onSave(value);
            }
          }

          setState(s => (s === 'view' ? 'edit' : 'view'));
        }}
      >
        <Icon>{state === 'view' ? 'edit' : 'done'}</Icon>
      </IconButton>
    </InfoInputContainer>
  );
};
