import create from 'zustand';

export type IDrawerStore = {
  open: boolean;
  width: number;
  setOpen: (state: boolean) => void;
  setWidth: (width: number) => void;
};

export const useDrawer = create<IDrawerStore>(set => ({
  open: false,
  width: 240,
  setOpen: state => {
    set(() => ({ open: state }));
  },
  setWidth: width => {
    set(() => ({ width }));
  }
}));
