import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  LatestDataInput,
  SlideDataInput,
  TrendingDataInput,
  useAddLatestMutation,
  useAddSlideMutation,
  useAddTrendingMutation,
  useLatestsQuery,
  useSlidesQuery,
  useTrendingsQuery
} from '../generated/graphql';

export const useMobileAddMutation = () => {
  const queryClient = useQueryClient();

  const addSlides = useAddSlideMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useSlidesQuery.getKey());
    }
  });

  const addLatest = useAddLatestMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useLatestsQuery.getKey());
    }
  });

  const addTrending = useAddTrendingMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useTrendingsQuery.getKey());
    }
  });

  const handleOnAddSlides = useCallback(
    (data: SlideDataInput) => {
      return addSlides.mutateAsync({
        data
      });
    },
    [addSlides]
  );

  const handleOnAddLatest = useCallback(
    (data: LatestDataInput) => {
      return addLatest.mutateAsync({
        data
      });
    },
    [addLatest]
  );

  const handleOnAddTrending = useCallback(
    (data: TrendingDataInput) => {
      return addTrending.mutateAsync({
        data
      });
    },
    [addTrending]
  );

  return {
    addSlide: handleOnAddSlides,
    addLatest: handleOnAddLatest,
    addTrending: handleOnAddTrending
  };
};
