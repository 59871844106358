import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Container, ContainerHeader, ArtistForm } from '../../components';
import {
  useArtistsQuery,
  useCreateArtistMutation
} from '../../generated/graphql';

export const ArtistAdd: FC = () => {
  const { replace } = useHistory();
  const queryClient = useQueryClient();
  const createArtist = useCreateArtistMutation({
    onSuccess: ({ createArtist }) => {
      queryClient.invalidateQueries(useArtistsQuery.getKey());
      replace(`/artist/${createArtist.id}`);
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Artist Add</Typography>
      </ContainerHeader>
      <Box
        sx={{
          py: 2,
          px: 5
        }}
      >
        <ArtistForm
          onSave={async data => {
            await createArtist.mutateAsync({ data });
          }}
        />
      </Box>
    </Container>
  );
};
