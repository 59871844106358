import { Checkbox, Icon, IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, ContainerHeader } from '../../components';
import { useSubscriptionPlansQuery } from '../../generated/graphql';
import { DataGridPro } from '../../vendor/x-grid';

export const SubscriptionPlanList: FC = () => {
  const { push } = useHistory();
  const { data, isLoading: loading } = useSubscriptionPlansQuery();

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Subscription Plans</Typography>
        <IconButton
          onClick={() => {
            push('/subscription/plan/add');
          }}
        >
          <Icon>add_box</Icon>
        </IconButton>
      </ContainerHeader>
      <DataGridPro
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 2
          },
          {
            field: 'offer',
            headerName: 'Offer',
            flex: 2,
            valueFormatter: ({ value }) => {
              if (value === null) {
                return 'N/A';
              }

              return `${value}%`;
            }
          },
          {
            field: 'quality',
            headerName: 'Video Quality',
            flex: 2,
            valueFormatter: ({ value }) => {
              return `${value}p`;
            }
          },
          {
            field: 'active',
            headerName: 'Active',
            flex: 1,
            renderCell: ({ value }) => {
              return <Checkbox checked={Boolean(value)} />;
            }
          }
        ]}
        rows={data?.subscriptionPlans ?? []}
        disableSelectionOnClick
        disableColumnSelector
        loading={loading}
        onRowClick={({ id }) => {
          push(`/subscription/plan/${id}`);
        }}
      />
    </Container>
  );
};
