import {
  Button,
  Divider,
  Icon,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import create from 'zustand';
import {
  Center,
  Container,
  ContainerHeader,
  SeriesEpisodeAddModal,
  SeriesEpisodeForm
} from '../../components';
import {
  SeriesEpisode as SeriesEpisodeType,
  SeriesSeason,
  useCreateSeriesEpisodeMutation,
  useCreateSeriesSeasonMutation,
  useSeriesEpisodesQuery,
  useSeriesSeasonsQuery,
  useUpdateSeriesEpisodeMutation
} from '../../generated/graphql';
import { usePaginationState } from '../../hooks';

type ISeason = Omit<SeriesSeason, 'episodes'>;
type IEpisode = SeriesEpisodeType;

type INavState = 'season' | 'episode';
type ISelected = { season?: ISeason; episode?: IEpisode };
type IUiState = {
  seriesId: string;
  navState: INavState;
  selected: ISelected;

  setSeriesId: (seriesId: string) => void;
  setNavState: (state: INavState) => void;
  setSelectedSeason: (season: ISelected['season']) => void;
  setSelectedEpisode: (episode: ISelected['episode']) => void;
};

const useUiState = create<IUiState>(set => ({
  seriesId: '',
  navState: 'season',
  selected: {},
  setSeriesId: (seriesId: string) => set({ seriesId }),
  setNavState: (navState: INavState) => set({ navState }),
  setSelectedSeason: (season: ISelected['season']) => {
    set(c => ({ selected: { ...c.selected, season } }));
  },
  setSelectedEpisode: (episode: ISelected['episode']) => {
    set(c => ({ selected: { ...c.selected, episode } }));
  }
}));

export const SeriesEpisode: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { navState, setSeriesId } = useUiState();

  useEffect(() => {
    setSeriesId(id);
  }, [id, setSeriesId]);

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Series ({id})</Typography>
      </ContainerHeader>
      <Box sx={{ display: 'flex', p: 1.5, flex: 1 }}>
        <SeriesEpisodeSideBar />
        {navState === 'season' ? <SeasonInfo /> : <EpisodeInfo />}
      </Box>
    </Container>
  );
};

const SeriesEpisodeSideBar: FC = () => {
  const queryClient = useQueryClient();
  const {
    seriesId,
    navState,
    selected,
    setNavState,
    setSelectedEpisode,
    setSelectedSeason
  } = useUiState();
  const [addSeasonModalOpen, setAddSeasonModalOpen] = useState(false);
  const { page, pageSize, query, setPage } = usePaginationState();
  const { data: seasonsRes } = useSeriesSeasonsQuery(
    { id: seriesId },
    { enabled: seriesId !== '' }
  );
  const { data: episodesRes } = useSeriesEpisodesQuery(
    { id: selected?.season?.id ?? '', query },
    { enabled: !!selected?.season?.id }
  );
  const createSeason = useCreateSeriesSeasonMutation({
    onSuccess: () => {
      queryClient.refetchQueries(
        useSeriesSeasonsQuery.getKey({ id: seriesId })
      );
    }
  });
  const seasons = useMemo(
    () => seasonsRes?.series?.seasons ?? [],
    [seasonsRes?.series?.seasons]
  );
  const episodes = useMemo(
    () => episodesRes?.seriesEpisodes,
    [episodesRes?.seriesEpisodes]
  );

  useEffect(() => {
    if (!selected?.season && seasons.length > 0) {
      setSelectedSeason(seasons[0]);
    }
  }, [seasons, selected, setSelectedSeason]);

  return (
    <Box
      sx={{
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'hidden'
      }}
    >
      <Dialog
        open={addSeasonModalOpen}
        onClose={() => setAddSeasonModalOpen(false)}
      >
        <DialogTitle>Add Season</DialogTitle>
        <DialogContent>
          Confirm to add a new season?
          <Typography fontWeight="bold">
            Season Number:&nbsp;
            {seasons.length > 0 && seasons[seasons.length - 1].number + 1}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddSeasonModalOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              await createSeason.mutateAsync({
                seriesId,
                number:
                  seasons.length > 0
                    ? seasons[seasons.length - 1].number + 1
                    : 1
              });
              setAddSeasonModalOpen(false);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            transition: 'all 0.3s ease-in-out',
            ...(navState === 'season'
              ? { transform: 'translateX(0)' }
              : { transform: 'translateX(-110%)' })
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setAddSeasonModalOpen(true);
            }}
          >
            Add Season
          </Button>
          {seasons.length !== 0 ? (
            <List component="nav" sx={{ flex: 1 }}>
              {seasons.map((s, idx) => {
                return (
                  <Fragment key={s.id}>
                    <ListItemButton
                      selected={selected.season?.id === s.id}
                      onClick={() => {
                        setSelectedSeason(s);
                      }}
                    >
                      <ListItemText primary={`Seasons ${s.number}`} />
                    </ListItemButton>
                    {seasons.length - 1 !== idx && <Divider />}
                  </Fragment>
                );
              })}
            </List>
          ) : (
            <Center sx={{ flex: 1 }}>
              <Typography>No Seasons</Typography>
            </Center>
          )}
        </Box>
        {episodes && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'all 0.3s ease-in-out',
              ...(navState === 'episode'
                ? { transform: 'translateX(0)' }
                : { transform: 'translateX(+110%)' })
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setNavState('season');
              }}
            >
              <Icon>chevron_left</Icon> Seasons
            </Button>
            {episodes.items.length !== 0 ? (
              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <List component="nav">
                  {episodes.items.map((e, idx) => {
                    return (
                      <Fragment key={e.id}>
                        <ListItemButton
                          selected={selected.episode?.id === e.id}
                          onClick={() => {
                            setSelectedEpisode(e);
                          }}
                        >
                          <ListItemText primary={`Episode ${e.number}`} />
                        </ListItemButton>
                        {seasons.length - 1 !== idx && <Divider />}
                      </Fragment>
                    );
                  })}
                </List>
                <TablePagination
                  component="div"
                  count={episodes.count ?? 0}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[pageSize]}
                  onPageChange={(_, p) => {
                    setPage(p);
                  }}
                />
              </Box>
            ) : (
              <Center sx={{ flex: 1 }}>
                <Typography>No Episodes</Typography>
              </Center>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SeasonInfo: FC = () => {
  const queryClient = useQueryClient();
  const { season } = useUiState(e => e.selected);
  const [modalOpen, setModalOpen] = useState(false);
  const onEpisodeClick = useUiState(e => {
    return (episode: IEpisode) => {
      e.setSelectedEpisode(episode);
      e.setNavState('episode');
    };
  });
  const { page, pageSize, query, setPage } = usePaginationState();
  const { data: episodesRes } = useSeriesEpisodesQuery(
    { id: season?.id ?? '', query },
    { enabled: !!season?.id }
  );
  const createEpisode = useCreateSeriesEpisodeMutation({
    onSuccess: () => {
      queryClient.refetchQueries(
        useSeriesEpisodesQuery.getKey({ id: season?.id ?? '' })
      );
      setModalOpen(false);
    }
  });
  const episodes = episodesRes?.seriesEpisodes;

  if (!season) {
    return (
      <Center sx={{ flex: 1 }}>
        <Typography sx={{ fontStyle: 'italic' }} component="span">
          Select a Season or Episode
        </Typography>
      </Center>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, ml: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }} fontWeight="bold">
          UUID
        </Typography>
        <Typography>{season.id}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }} fontWeight="bold">
          Number
        </Typography>
        <Typography>{season.number}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }} fontWeight="bold">
          Total Episodes
        </Typography>
        <Typography>{episodes?.count ?? 0}</Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <SeriesEpisodeAddModal
          open={modalOpen}
          season={season}
          onSave={async data => {
            await createEpisode.mutateAsync({ seasonId: season.id, data });
          }}
          onCancel={() => setModalOpen(false)}
        />
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add Episode
        </Button>
      </Box>

      {episodes && episodes.count === 0 && (
        <Center sx={{ flex: 1 }}>
          <Typography fontStyle="italic">No Episodes</Typography>
        </Center>
      )}
      <Box sx={{ display: 'flex' }}>
        {episodes?.count !== 0 && (
          <ImageList component="div" cols={3} sx={{ m: 0, overflow: 'hidden' }}>
            {(episodes?.items ?? []).map(m => (
              <ImageListItem
                key={m.id}
                onClick={() => {
                  if (onEpisodeClick) {
                    onEpisodeClick(m);
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  transition: 'all 0.1s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.01)'
                  }
                }}
              >
                <img
                  style={{ width: '100%', aspectRatio: '16 / 9' }}
                  src={m.thumbnail ?? 'http://lorempixel.com/320/460/nature'}
                  alt={m.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={m.title}
                  subtitle={`Ep. ${m.number}`}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>

      <Box sx={{ flex: 1 }} />

      <TablePagination
        component="div"
        count={episodesRes?.seriesEpisodes.count ?? 0}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(_, p) => {
          setPage(p);
        }}
      />
    </Box>
  );
};

const EpisodeInfo: FC = () => {
  const queryClient = useQueryClient();
  const { season, episode } = useUiState(e => e.selected);
  const updateEpisode = useUpdateSeriesEpisodeMutation({
    onSuccess: () => {
      queryClient.refetchQueries(
        useSeriesEpisodesQuery.getKey({ id: season?.id ?? '' })
      );
    }
  });

  if (!season || !episode) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, ml: 2 }}>
      <SeriesEpisodeForm
        data={episode}
        season={season}
        onUpdate={async data => {
          await updateEpisode.mutateAsync({
            id: episode.id,
            data
          });
        }}
      />
    </Box>
  );
};
