import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  IconButton,
  Icon,
  Divider,
  Modal,
  TextField,
  Chip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  ArtistSearchQuery,
  useArtistSearchQuery
} from '../../../generated/graphql';
import { ModalContent } from '../../ModalContent';
import { Center } from '../../Center';

export type Casts = ArtistSearchQuery['artistSearch']['items'];

export type CastInputProps = {
  casts: Casts;
  onSave?: (artists: Casts) => Promise<void>;
};

type CastModalProps = CastInputProps & {
  open: boolean;
  onCancel?: () => void;
};

const useSearchRequest = () => {
  const [currentKeyword, setCurrentKeyword] = useState('');
  const { data: searchRes, isLoading: loading } = useArtistSearchQuery(
    { keyword: currentKeyword, query: { take: 20, skip: 0 } },
    { enabled: currentKeyword !== '' }
  );
  const [data, setData] = useState<Casts>([]);
  const loadSearch = setCurrentKeyword;

  useEffect(() => {
    setData(searchRes?.artistSearch?.items ?? []);
  }, [searchRes?.artistSearch?.items]);

  return {
    data,
    loading,
    loadSearch
  };
};

const CastModal: FC<CastModalProps> = ({ open, casts, onSave, onCancel }) => {
  const { data: searchData, loadSearch } = useSearchRequest();
  const [selected, setSelected] = useState<Casts>([]);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    setSelected(casts);
  }, [casts]);

  useEffect(() => {
    setSelectedIds(new Set(selected.map(({ id }) => id)));
  }, [selected]);

  return (
    <Modal open={open}>
      <ModalContent
        sx={{
          width: '35%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ px: 2, mt: 2 }}>
          <Typography variant="h5">Casts</Typography>
          <Divider sx={{ mb: 2 }} />
          <TextField
            fullWidth
            variant="standard"
            label="Search"
            onChange={e => {
              loadSearch(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ overflowY: 'auto', flexGrow: 1, my: 1.5 }}>
          <Box sx={{ my: 1, mx: 2 }}>
            {selected.map(a => (
              <Chip
                key={a.id}
                label={a.name}
                sx={{ m: 0.2 }}
                variant="outlined"
                color="info"
                onDelete={() => {
                  setSelected(s => s.filter(b => b.id !== a.id));
                }}
              />
            ))}
          </Box>
          <Divider />
          {searchData
            .filter(a => !selectedIds.has(a.id))
            .map((a, idx) => (
              <React.Fragment key={a.id}>
                <ListItemButton
                  onClick={() => {
                    setSelected(s => [...s, a]);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={a.avatar ?? ''} alt={a.name} />
                  </ListItemAvatar>
                  <ListItemText primary={a.name} />
                </ListItemButton>
                {searchData.length - 1 !== idx && <Divider />}
              </React.Fragment>
            ))}
        </Box>
        <Box
          sx={{
            pb: 2,
            px: 2,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <LoadingButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            onClick={async () => {
              if (onSave) {
                await onSave(selected);
              }
            }}
          >
            Save
          </LoadingButton>
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const CastInput: FC<CastInputProps> = ({ casts, onSave }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">Casts</Typography>
        <IconButton
          size="small"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Box>
      <CastModal
        open={modalOpen}
        casts={casts}
        onSave={async (artists: Casts) => {
          if (onSave) {
            await onSave(artists);
          }

          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      />
      <Divider sx={{ mb: 1 }} />
      {casts.length === 0 && <Center sx={{ p: 2 }}>No Casts</Center>}
      {casts.map(c => (
        <Chip
          key={c.id}
          avatar={<Avatar src={c.avatar ?? ''} alt={c.name} />}
          label={c.name}
          variant="outlined"
          color="info"
          clickable
          sx={{ m: 0.2 }}
        />
      ))}
    </Box>
  );
};
