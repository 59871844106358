import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
  Container,
  ContainerHeader,
  SubscriptionPlanForm
} from '../../components';
import {
  useCreateSubscriptionPlanMutation,
  useSubscriptionPlansQuery
} from '../../generated/graphql';

export const SubscriptionPlanAdd: FC = () => {
  const queryClient = useQueryClient();
  const { replace } = useHistory();
  const createSubscriptionPlan = useCreateSubscriptionPlanMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useSubscriptionPlansQuery.getKey());
      replace('/subscription/plan/list');
    }
  });

  return (
    <Container>
      <ContainerHeader>
        <Typography variant="h5">Add Subscription Plan</Typography>
      </ContainerHeader>
      <Box sx={{ p: 2 }}>
        <SubscriptionPlanForm
          onSave={async data => {
            await createSubscriptionPlan.mutateAsync({ data });
          }}
        />
      </Box>
    </Container>
  );
};
