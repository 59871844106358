import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from 'react-query';
import { fetchData } from './../fetcher';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Artist = {
  __typename?: 'Artist';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  followCount: Scalars['Int'];
  followed: Scalars['Boolean'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shouldShow: Scalars['Boolean'];
  tag?: Maybe<Scalars['String']>;
};

export type ArtistDataInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
};

export type ArtistDataUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type AuthLogInInput = {
  aud: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type AuthLogInRes = {
  __typename?: 'AuthLogInRes';
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AuthPasswordResetInput = {
  password: Scalars['String'];
  passwordAgain: Scalars['String'];
  phone: Scalars['String'];
  token: Scalars['String'];
};

export type AuthRegisterInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  passwordAgain: Scalars['String'];
  phone: Scalars['String'];
  token: Scalars['String'];
};

export type Config = {
  __typename?: 'Config';
  androidAppForceUpdate: Scalars['Boolean'];
  androidAppVersion: Scalars['Int'];
  razorPayKey: Scalars['String'];
  supportWhatsappPhoneNumber: Scalars['String'];
};

export enum ContentType {
  Movie = 'MOVIE',
  Music = 'MUSIC',
  Series = 'SERIES',
  TalkShow = 'TALK_SHOW'
}

export type Genre = {
  __typename?: 'Genre';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type GenreDataInput = {
  title: Scalars['String'];
};

export type GenreDataUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type Latest = {
  __typename?: 'Latest';
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type LatestDataInput = {
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type LatestResultUnion = Movie | Music | Series | TalkShow;

export type Media = {
  __typename?: 'Media';
  id: Scalars['ID'];
  mediaId: Scalars['String'];
  type: MediaType;
  url?: Maybe<Scalars['String']>;
};

export type MediaDataInput = {
  mediaId: Scalars['String'];
  type: MediaType;
  url?: Maybe<Scalars['String']>;
};

export type MediaDataUpdateInput = {
  url?: Maybe<Scalars['String']>;
};

export type MediaRes = {
  __typename?: 'MediaRes';
  status: MediaStatus;
  url?: Maybe<Scalars['String']>;
};

export enum MediaStatus {
  NotAvailable = 'NOT_AVAILABLE',
  NotPurchased = 'NOT_PURCHASED',
  NotReleased = 'NOT_RELEASED',
  NotSubscribed = 'NOT_SUBSCRIBED',
  Ok = 'OK'
}

export enum MediaType {
  Movie = 'MOVIE',
  Music = 'MUSIC',
  SeriesEpisode = 'SERIES_EPISODE',
  TalkshowEpisode = 'TALKSHOW_EPISODE'
}

export type Mobile = {
  __typename?: 'Mobile';
  artists: Array<Artist>;
  latests: Array<LatestResultUnion>;
  movies: Array<Movie>;
  musics: Array<Music>;
  series: Array<Series>;
  slides: Array<SlideResultUnion>;
  talkShows: Array<TalkShow>;
  trendings: Array<TrendingResultUnion>;
};

export type MobileComingSoon = {
  __typename?: 'MobileComingSoon';
  id: Scalars['String'];
  releasedDate: Scalars['DateTime'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Movie = {
  __typename?: 'Movie';
  artists: Array<Artist>;
  description?: Maybe<Scalars['String']>;
  genres: Array<Genre>;
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  liked: Scalars['Boolean'];
  media: MediaRes;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  recommendations: Array<Movie>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  shouldShow: Scalars['Boolean'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type MovieDataInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  recommendations?: Maybe<Array<Scalars['String']>>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type MovieDataUpdateInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  recommendations?: Maybe<Array<Scalars['String']>>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailer?: Maybe<Scalars['String']>;
};

export type MoviePaymentInput = {
  movie: Scalars['String'];
};

export type Music = {
  __typename?: 'Music';
  artists: Array<Artist>;
  description?: Maybe<Scalars['String']>;
  genres: Array<Genre>;
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  liked: Scalars['Boolean'];
  media: MediaRes;
  poster?: Maybe<Scalars['String']>;
  recommendations: Array<Music>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  shouldShow: Scalars['Boolean'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type MusicDataInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Array<Scalars['String']>>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type MusicDataUpdateInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Array<Scalars['String']>>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  runningTime?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailer?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addLatest: Latest;
  addSlide: Slide;
  addTrending: Trending;
  artistFollow: Scalars['Boolean'];
  artistUnfollow: Scalars['Boolean'];
  authLogIn: AuthLogInRes;
  authLogOut: Scalars['Boolean'];
  authPasswordReset: Scalars['Boolean'];
  authRegister: User;
  authTokenRefresh: AuthLogInRes;
  confirmPayment: PaymentRes;
  createArtist: Artist;
  createGenre: Genre;
  createMedia: Media;
  createMovie: Movie;
  createMoviePaymentOrder: Scalars['String'];
  createMusic: Music;
  createSeries: Series;
  createSeriesEpisode: SeriesEpisode;
  createSeriesPaymentOrder: Scalars['String'];
  createSeriesSeason: SeriesSeason;
  createSubscriptionPaymentOrder: Scalars['String'];
  createSubscriptionPlan: SubscriptionPlan;
  createSubscriptionPlanPrice: SubscriptionPlanPrice;
  createTalkShow: TalkShow;
  createTalkShowEpisode: TalkShowEpisode;
  movieDislike: Scalars['Boolean'];
  movieLike: Scalars['Boolean'];
  musicDislike: Scalars['Boolean'];
  musicLike: Scalars['Boolean'];
  removeLatest: Scalars['Boolean'];
  removeSlide: Scalars['Boolean'];
  removeTrending: Scalars['Boolean'];
  sendOtp: Scalars['String'];
  seriesDislike: Scalars['Boolean'];
  seriesLike: Scalars['Boolean'];
  talkShowDislike: Scalars['Boolean'];
  talkShowLike: Scalars['Boolean'];
  updateArtist: Artist;
  updateGenre: Genre;
  updateLatest: Latest;
  updateMedia: Media;
  updateMovie: Movie;
  updateMusic: Music;
  updateSeries: Series;
  updateSeriesEpisode: SeriesEpisode;
  updateSeriesSeason: SeriesSeason;
  updateSlide: Slide;
  updateSubscriptionPlan: SubscriptionPlan;
  updateSubscriptionPlanPrice: SubscriptionPlanPrice;
  updateTalkShow: TalkShow;
  updateTalkShowEpisode: TalkShowEpisode;
  updateTrending: Trending;
  updateUser: User;
  userPasswordChange: Scalars['Boolean'];
  userPhoneChange: Scalars['Boolean'];
  userPhoneChangeOtp: Scalars['String'];
  verifyOtp: Scalars['String'];
};

export type MutationAddLatestArgs = {
  data: LatestDataInput;
};

export type MutationAddSlideArgs = {
  data: SlideDataInput;
};

export type MutationAddTrendingArgs = {
  data: TrendingDataInput;
};

export type MutationArtistFollowArgs = {
  artistId: Scalars['ID'];
};

export type MutationArtistUnfollowArgs = {
  artistId: Scalars['ID'];
};

export type MutationAuthLogInArgs = {
  data: AuthLogInInput;
};

export type MutationAuthPasswordResetArgs = {
  data: AuthPasswordResetInput;
};

export type MutationAuthRegisterArgs = {
  data: AuthRegisterInput;
};

export type MutationAuthTokenRefreshArgs = {
  refreshToken: Scalars['String'];
};

export type MutationConfirmPaymentArgs = {
  data: PaymentConfirmInput;
};

export type MutationCreateArtistArgs = {
  data: ArtistDataInput;
};

export type MutationCreateGenreArgs = {
  data: GenreDataInput;
};

export type MutationCreateMediaArgs = {
  data: MediaDataInput;
};

export type MutationCreateMovieArgs = {
  data: MovieDataInput;
};

export type MutationCreateMoviePaymentOrderArgs = {
  data: MoviePaymentInput;
};

export type MutationCreateMusicArgs = {
  data: MusicDataInput;
};

export type MutationCreateSeriesArgs = {
  data: SeriesDataInput;
};

export type MutationCreateSeriesEpisodeArgs = {
  data: SeriesEpisodeDataInput;
  seasonId: Scalars['ID'];
};

export type MutationCreateSeriesPaymentOrderArgs = {
  data: SeriesPaymentInput;
};

export type MutationCreateSeriesSeasonArgs = {
  data: SeriesSeasonDataInput;
};

export type MutationCreateSubscriptionPaymentOrderArgs = {
  data: SubscriptionPaymentInput;
};

export type MutationCreateSubscriptionPlanArgs = {
  data: SubscriptionPlanDataInput;
};

export type MutationCreateSubscriptionPlanPriceArgs = {
  data: SubscriptionPlanPriceDataInput;
  id: Scalars['ID'];
};

export type MutationCreateTalkShowArgs = {
  data: TalkShowDataInput;
};

export type MutationCreateTalkShowEpisodeArgs = {
  data: TalkShowEpisodeDataInput;
  showId: Scalars['ID'];
};

export type MutationMovieDislikeArgs = {
  movieId: Scalars['String'];
};

export type MutationMovieLikeArgs = {
  movieId: Scalars['String'];
};

export type MutationMusicDislikeArgs = {
  musicId: Scalars['String'];
};

export type MutationMusicLikeArgs = {
  musicId: Scalars['ID'];
};

export type MutationRemoveLatestArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveSlideArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveTrendingArgs = {
  id: Scalars['Int'];
};

export type MutationSendOtpArgs = {
  data: OtpDataInput;
};

export type MutationSeriesDislikeArgs = {
  seriesId: Scalars['ID'];
};

export type MutationSeriesLikeArgs = {
  seriesId: Scalars['ID'];
};

export type MutationTalkShowDislikeArgs = {
  talkShowId: Scalars['ID'];
};

export type MutationTalkShowLikeArgs = {
  talkShowId: Scalars['ID'];
};

export type MutationUpdateArtistArgs = {
  data: ArtistDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateGenreArgs = {
  data: GenreDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateLatestArgs = {
  id: Scalars['Int'];
  rank: Scalars['Int'];
};

export type MutationUpdateMediaArgs = {
  data: MediaDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateMovieArgs = {
  data: MovieDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateMusicArgs = {
  data: MusicDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateSeriesArgs = {
  data: SeriesDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateSeriesEpisodeArgs = {
  data: SeriesEpisodeDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateSeriesSeasonArgs = {
  data: SeriesSeasonDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateSlideArgs = {
  id: Scalars['Int'];
  rank: Scalars['Int'];
};

export type MutationUpdateSubscriptionPlanArgs = {
  data: SubscriptionPlanDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateSubscriptionPlanPriceArgs = {
  data: SubscriptionPlanPriceDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateTalkShowArgs = {
  data: TalkShowDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateTalkShowEpisodeArgs = {
  data: TalkShowEpisodeDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateTrendingArgs = {
  id: Scalars['Int'];
  rank: Scalars['Int'];
};

export type MutationUpdateUserArgs = {
  data: UserDataUpdateInput;
  id: Scalars['ID'];
};

export type MutationUserPasswordChangeArgs = {
  data: UserPasswordChangeInput;
};

export type MutationUserPhoneChangeArgs = {
  data: UserPhoneChangeInput;
};

export type MutationUserPhoneChangeOtpArgs = {
  phone: Scalars['String'];
};

export type MutationVerifyOtpArgs = {
  data: OtpVerifyDataInput;
  id: Scalars['ID'];
};

export type OtpDataInput = {
  for: Scalars['String'];
  to: Scalars['String'];
};

export type OtpVerifyDataInput = {
  for: Scalars['String'];
  to: Scalars['String'];
  token: Scalars['String'];
};

export type PaginatedArtists = {
  __typename?: 'PaginatedArtists';
  count: Scalars['Int'];
  items: Array<Artist>;
  query: PaginationQuery;
};

export type PaginatedEpisodes = {
  __typename?: 'PaginatedEpisodes';
  count: Scalars['Int'];
  items: Array<SeriesEpisode>;
  query: PaginationQuery;
};

export type PaginatedGenres = {
  __typename?: 'PaginatedGenres';
  count: Scalars['Int'];
  items: Array<Genre>;
  query: PaginationQuery;
};

export type PaginatedLatest = {
  __typename?: 'PaginatedLatest';
  count: Scalars['Int'];
  items: Array<PaginatedLatestItem>;
  query: PaginationQuery;
};

export type PaginatedLatestItem = {
  __typename?: 'PaginatedLatestItem';
  id: Scalars['Int'];
  item: LatestResultUnion;
  rank: Scalars['Int'];
};

export type PaginatedMovies = {
  __typename?: 'PaginatedMovies';
  count: Scalars['Int'];
  items: Array<Movie>;
  query: PaginationQuery;
};

export type PaginatedMusics = {
  __typename?: 'PaginatedMusics';
  count: Scalars['Int'];
  items: Array<Music>;
  query: PaginationQuery;
};

export type PaginatedSeries = {
  __typename?: 'PaginatedSeries';
  count: Scalars['Int'];
  items: Array<Series>;
  query: PaginationQuery;
};

export type PaginatedSlide = {
  __typename?: 'PaginatedSlide';
  count: Scalars['Int'];
  items: Array<SlideLatestItem>;
  query: PaginationQuery;
};

export type PaginatedTalkShow = {
  __typename?: 'PaginatedTalkShow';
  count: Scalars['Int'];
  items: Array<TalkShow>;
  query: PaginationQuery;
};

export type PaginatedTalkShowEpisode = {
  __typename?: 'PaginatedTalkShowEpisode';
  count: Scalars['Int'];
  items: Array<TalkShowEpisode>;
  query: PaginationQuery;
};

export type PaginatedTrending = {
  __typename?: 'PaginatedTrending';
  count: Scalars['Int'];
  items: Array<PaginatedTrendingItem>;
  query: PaginationQuery;
};

export type PaginatedTrendingItem = {
  __typename?: 'PaginatedTrendingItem';
  id: Scalars['Int'];
  item: TrendingResultUnion;
  rank: Scalars['Int'];
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  count: Scalars['Int'];
  items: Array<User>;
  query: PaginationQuery;
};

export type PaginationQuery = {
  __typename?: 'PaginationQuery';
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaginationQueryInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Payment = {
  __typename?: 'Payment';
  failed: Scalars['Boolean'];
  orderId: Scalars['String'];
  paid: Scalars['Boolean'];
  paymentId: Scalars['String'];
  processed: Scalars['Boolean'];
};

export type PaymentConfirmInput = {
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  razorpay_signature: Scalars['String'];
};

export type PaymentRes = {
  __typename?: 'PaymentRes';
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  admin: User;
  artist?: Maybe<Artist>;
  artistSearch: PaginatedArtists;
  artists: PaginatedArtists;
  config: Config;
  episode?: Maybe<SeriesEpisode>;
  genre?: Maybe<Genre>;
  genres: PaginatedGenres;
  isUserExist: Scalars['Boolean'];
  latest: PaginatedLatest;
  me: User;
  media: Media;
  mobile: Mobile;
  mobileComingSoon: Array<MobileComingSoon>;
  movie?: Maybe<Movie>;
  movies: PaginatedMovies;
  music?: Maybe<Music>;
  musics: PaginatedMusics;
  payment?: Maybe<Payment>;
  search: Array<SearchResultUnion>;
  series?: Maybe<Series>;
  seriesAll: PaginatedSeries;
  seriesEpisodes: PaginatedEpisodes;
  seriesSeason?: Maybe<SeriesSeason>;
  slide: PaginatedSlide;
  subscription?: Maybe<Subscription>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlans: Array<SubscriptionPlan>;
  talkShow?: Maybe<TalkShow>;
  talkShowAll: PaginatedTalkShow;
  talkShowEpisode?: Maybe<TalkShowEpisode>;
  talkShowEpisodes: PaginatedTalkShowEpisode;
  trending: PaginatedTrending;
  user?: Maybe<User>;
  users: PaginatedUsers;
};

export type QueryArtistArgs = {
  id: Scalars['ID'];
};

export type QueryArtistSearchArgs = {
  keyword: Scalars['String'];
  query?: Maybe<PaginationQueryInput>;
};

export type QueryArtistsArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type QueryEpisodeArgs = {
  id: Scalars['ID'];
};

export type QueryGenreArgs = {
  id: Scalars['ID'];
};

export type QueryGenresArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type QueryIsUserExistArgs = {
  phone: Scalars['String'];
};

export type QueryLatestArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type QueryMediaArgs = {
  id: Scalars['ID'];
};

export type QueryMovieArgs = {
  id: Scalars['ID'];
};

export type QueryMoviesArgs = {
  artists?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<PaginationQueryInput>;
};

export type QueryMusicArgs = {
  id: Scalars['ID'];
};

export type QueryMusicsArgs = {
  artists?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<PaginationQueryInput>;
};

export type QueryPaymentArgs = {
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
};

export type QuerySearchArgs = {
  filter?: Maybe<Scalars['String']>;
  keyword: Scalars['String'];
};

export type QuerySeriesArgs = {
  id: Scalars['ID'];
};

export type QuerySeriesAllArgs = {
  artists?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<PaginationQueryInput>;
};

export type QuerySeriesEpisodesArgs = {
  query?: Maybe<PaginationQueryInput>;
  seasonId: Scalars['ID'];
};

export type QuerySeriesSeasonArgs = {
  id: Scalars['ID'];
};

export type QuerySlideArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['ID'];
};

export type QueryTalkShowArgs = {
  id: Scalars['ID'];
};

export type QueryTalkShowAllArgs = {
  artists?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<PaginationQueryInput>;
};

export type QueryTalkShowEpisodeArgs = {
  id: Scalars['ID'];
};

export type QueryTalkShowEpisodesArgs = {
  query?: Maybe<PaginationQueryInput>;
  showId: Scalars['ID'];
};

export type QueryTrendingArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  query?: Maybe<PaginationQueryInput>;
};

export type SearchArtist = {
  __typename?: 'SearchArtist';
  avatar: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SearchMovie = {
  __typename?: 'SearchMovie';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};

export type SearchMusic = {
  __typename?: 'SearchMusic';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};

export type SearchResultUnion =
  | SearchArtist
  | SearchMovie
  | SearchMusic
  | SearchSeries
  | SearchTalkShow;

export type SearchSeries = {
  __typename?: 'SearchSeries';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};

export type SearchTalkShow = {
  __typename?: 'SearchTalkShow';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};

export type Series = {
  __typename?: 'Series';
  artists: Array<Artist>;
  description?: Maybe<Scalars['String']>;
  genres: Array<Genre>;
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  liked: Scalars['Boolean'];
  media: MediaRes;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  seasons: Array<SeriesSeason>;
  shouldShow: Scalars['Boolean'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type SeriesDataInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type SeriesDataUpdateInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  mediaUrl?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailer?: Maybe<Scalars['String']>;
};

export type SeriesEpisode = {
  __typename?: 'SeriesEpisode';
  description: Scalars['String'];
  id: Scalars['ID'];
  mediaUrl?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SeriesEpisodeDataInput = {
  description: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SeriesEpisodeDataUpdateInput = {
  description?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeriesPaymentInput = {
  series: Scalars['String'];
};

export type SeriesSeason = {
  __typename?: 'SeriesSeason';
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type SeriesSeasonDataInput = {
  number: Scalars['Int'];
  series: Scalars['String'];
};

export type SeriesSeasonDataUpdateInput = {
  number: Scalars['Int'];
};

export type Slide = {
  __typename?: 'Slide';
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type SlideDataInput = {
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type SlideLatestItem = {
  __typename?: 'SlideLatestItem';
  id: Scalars['Int'];
  item: SlideResultUnion;
  rank: Scalars['Int'];
};

export type SlideResultUnion = Movie | Music | Series | TalkShow;

export type Subscription = {
  __typename?: 'Subscription';
  expiresAt: Scalars['String'];
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
  plan: SubscriptionPlan;
  planPrice: SubscriptionPlanPrice;
  subscribedAt: Scalars['String'];
  user: User;
};

export type SubscriptionPaymentInput = {
  plan: Scalars['String'];
  planPrice: Scalars['String'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  active: Scalars['Boolean'];
  ads: Scalars['Boolean'];
  background: Scalars['Boolean'];
  coin: Scalars['Boolean'];
  createdAt: Scalars['String'];
  download: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offer?: Maybe<Scalars['Int']>;
  originals: Scalars['Boolean'];
  pip: Scalars['Boolean'];
  prices: Array<SubscriptionPlanPrice>;
  quality: Scalars['String'];
  tvod: Scalars['Boolean'];
  updatedAt: Scalars['String'];
};

export type SubscriptionPlanDataInput = {
  active: Scalars['Boolean'];
  ads: Scalars['Boolean'];
  background: Scalars['Boolean'];
  coin: Scalars['Boolean'];
  download: Scalars['Boolean'];
  name: Scalars['String'];
  offer?: Maybe<Scalars['Int']>;
  originals: Scalars['Boolean'];
  pip: Scalars['Boolean'];
  prices: Array<SubscriptionPlanPriceDataInput>;
  quality: Scalars['String'];
  tvod: Scalars['Boolean'];
};

export type SubscriptionPlanDataUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  ads?: Maybe<Scalars['Boolean']>;
  background?: Maybe<Scalars['Boolean']>;
  coin?: Maybe<Scalars['Boolean']>;
  download?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['Int']>;
  originals?: Maybe<Scalars['Boolean']>;
  pip?: Maybe<Scalars['Boolean']>;
  quality?: Maybe<Scalars['String']>;
  tvod?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionPlanPrice = {
  __typename?: 'SubscriptionPlanPrice';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  time: Scalars['String'];
};

export type SubscriptionPlanPriceDataInput = {
  active: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Int'];
  time: Scalars['String'];
};

export type SubscriptionPlanPriceDataUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
};

export type TalkShow = {
  __typename?: 'TalkShow';
  artists: Array<Artist>;
  description?: Maybe<Scalars['String']>;
  episodes: Array<TalkShowEpisode>;
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  liked: Scalars['Boolean'];
  media: MediaRes;
  poster?: Maybe<Scalars['String']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  shouldShow: Scalars['Boolean'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type TalkShowDataInput = {
  artists: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailer?: Maybe<Scalars['String']>;
};

export type TalkShowDataUpdateInput = {
  artists?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  poster?: Maybe<Scalars['String']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailer?: Maybe<Scalars['String']>;
};

export type TalkShowEpisode = {
  __typename?: 'TalkShowEpisode';
  description: Scalars['String'];
  id: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};

export type TalkShowEpisodeDataInput = {
  description: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TalkShowEpisodeDataUpdateInput = {
  description?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Trending = {
  __typename?: 'Trending';
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type TrendingDataInput = {
  contentId: Scalars['String'];
  contentType: ContentType;
  rank: Scalars['Int'];
};

export type TrendingResultUnion = Movie | Music | Series | TalkShow;

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type UserDataUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type UserPasswordChangeInput = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
  passwordAgain: Scalars['String'];
};

export type UserPhoneChangeInput = {
  otpId: Scalars['String'];
  phone: Scalars['String'];
  token: Scalars['String'];
};

export type CreateArtistMutationVariables = Exact<{
  data: ArtistDataInput;
}>;

export type CreateArtistMutation = {
  __typename?: 'Mutation';
  createArtist: {
    __typename?: 'Artist';
    id: string;
    avatar?: string | null | undefined;
    name: string;
    gender?: string | null | undefined;
    bio?: string | null | undefined;
    dob?: string | null | undefined;
    address?: string | null | undefined;
    tag?: string | null | undefined;
  };
};

export type UpdateArtistMutationVariables = Exact<{
  id: Scalars['ID'];
  data: ArtistDataUpdateInput;
}>;

export type UpdateArtistMutation = {
  __typename?: 'Mutation';
  updateArtist: {
    __typename?: 'Artist';
    id: string;
    avatar?: string | null | undefined;
    name: string;
    gender?: string | null | undefined;
    bio?: string | null | undefined;
    dob?: string | null | undefined;
    address?: string | null | undefined;
    tag?: string | null | undefined;
  };
};

export type LogInMutationVariables = Exact<{
  data: AuthLogInInput;
}>;

export type LogInMutation = {
  __typename?: 'Mutation';
  authLogIn: { __typename?: 'AuthLogInRes'; jwt: string; refreshToken: string };
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = { __typename?: 'Mutation'; authLogOut: boolean };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  authTokenRefresh: {
    __typename?: 'AuthLogInRes';
    jwt: string;
    refreshToken: string;
  };
};

export type CreateGenreMutationVariables = Exact<{
  data: GenreDataInput;
}>;

export type CreateGenreMutation = {
  __typename?: 'Mutation';
  createGenre: { __typename?: 'Genre'; id: string; title: string };
};

export type UpdateGenreMutationVariables = Exact<{
  id: Scalars['ID'];
  data: GenreDataUpdateInput;
}>;

export type UpdateGenreMutation = {
  __typename?: 'Mutation';
  updateGenre: { __typename?: 'Genre'; id: string; title: string };
};

export type AddLatestMutationVariables = Exact<{
  data: LatestDataInput;
}>;

export type AddLatestMutation = {
  __typename?: 'Mutation';
  addLatest: {
    __typename?: 'Latest';
    contentType: ContentType;
    contentId: string;
    rank: number;
  };
};

export type UpdateLatestMutationVariables = Exact<{
  id: Scalars['Int'];
  rank: Scalars['Int'];
}>;

export type UpdateLatestMutation = {
  __typename?: 'Mutation';
  updateLatest: {
    __typename?: 'Latest';
    contentType: ContentType;
    contentId: string;
    rank: number;
  };
};

export type RemoveLatestMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveLatestMutation = {
  __typename?: 'Mutation';
  removeLatest: boolean;
};

export type CreateMovieMutationVariables = Exact<{
  data: MovieDataInput;
}>;

export type CreateMovieMutation = {
  __typename?: 'Mutation';
  createMovie: { __typename?: 'Movie'; id: string };
};

export type UpdateMovieMutationVariables = Exact<{
  id: Scalars['ID'];
  data: MovieDataUpdateInput;
}>;

export type UpdateMovieMutation = {
  __typename?: 'Mutation';
  updateMovie: { __typename?: 'Movie'; id: string };
};

export type CreateMusicMutationVariables = Exact<{
  data: MusicDataInput;
}>;

export type CreateMusicMutation = {
  __typename?: 'Mutation';
  createMusic: { __typename?: 'Music'; id: string };
};

export type UpdateMusicMutationVariables = Exact<{
  id: Scalars['ID'];
  data: MusicDataUpdateInput;
}>;

export type UpdateMusicMutation = {
  __typename?: 'Mutation';
  updateMusic: { __typename?: 'Music'; id: string };
};

export type CreateSeriesMutationVariables = Exact<{
  data: SeriesDataInput;
}>;

export type CreateSeriesMutation = {
  __typename?: 'Mutation';
  createSeries: { __typename?: 'Series'; id: string };
};

export type UpdateSeriesMutationVariables = Exact<{
  id: Scalars['ID'];
  data: SeriesDataUpdateInput;
}>;

export type UpdateSeriesMutation = {
  __typename?: 'Mutation';
  updateSeries: { __typename?: 'Series'; id: string };
};

export type CreateSeriesSeasonMutationVariables = Exact<{
  number: Scalars['Int'];
  seriesId: Scalars['String'];
}>;

export type CreateSeriesSeasonMutation = {
  __typename?: 'Mutation';
  createSeriesSeason: {
    __typename?: 'SeriesSeason';
    id: string;
    number: number;
  };
};

export type CreateSeriesEpisodeMutationVariables = Exact<{
  seasonId: Scalars['ID'];
  data: SeriesEpisodeDataInput;
}>;

export type CreateSeriesEpisodeMutation = {
  __typename?: 'Mutation';
  createSeriesEpisode: { __typename?: 'SeriesEpisode'; id: string };
};

export type UpdateSeriesEpisodeMutationVariables = Exact<{
  id: Scalars['ID'];
  data: SeriesEpisodeDataUpdateInput;
}>;

export type UpdateSeriesEpisodeMutation = {
  __typename?: 'Mutation';
  updateSeriesEpisode: { __typename?: 'SeriesEpisode'; id: string };
};

export type AddSlideMutationVariables = Exact<{
  data: SlideDataInput;
}>;

export type AddSlideMutation = {
  __typename?: 'Mutation';
  addSlide: {
    __typename?: 'Slide';
    rank: number;
    contentType: ContentType;
    contentId: string;
  };
};

export type UpdateSlideMutationVariables = Exact<{
  id: Scalars['Int'];
  rank: Scalars['Int'];
}>;

export type UpdateSlideMutation = {
  __typename?: 'Mutation';
  updateSlide: {
    __typename?: 'Slide';
    rank: number;
    contentType: ContentType;
    contentId: string;
  };
};

export type RemoveSlideMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveSlideMutation = {
  __typename?: 'Mutation';
  removeSlide: boolean;
};

export type CreateSubscriptionPlanMutationVariables = Exact<{
  data: SubscriptionPlanDataInput;
}>;

export type CreateSubscriptionPlanMutation = {
  __typename?: 'Mutation';
  createSubscriptionPlan: { __typename?: 'SubscriptionPlan'; id: string };
};

export type UpdateSubscriptionPlanMutationVariables = Exact<{
  id: Scalars['ID'];
  data: SubscriptionPlanDataUpdateInput;
}>;

export type UpdateSubscriptionPlanMutation = {
  __typename?: 'Mutation';
  updateSubscriptionPlan: { __typename?: 'SubscriptionPlan'; id: string };
};

export type CreateSubscriptionPlanPriceMutationVariables = Exact<{
  data: SubscriptionPlanPriceDataInput;
  id: Scalars['ID'];
}>;

export type CreateSubscriptionPlanPriceMutation = {
  __typename?: 'Mutation';
  createSubscriptionPlanPrice: {
    __typename?: 'SubscriptionPlanPrice';
    id: string;
  };
};

export type UpdateSubscriptionPlanPriceMutationVariables = Exact<{
  id: Scalars['ID'];
  data: SubscriptionPlanPriceDataUpdateInput;
}>;

export type UpdateSubscriptionPlanPriceMutation = {
  __typename?: 'Mutation';
  updateSubscriptionPlanPrice: {
    __typename?: 'SubscriptionPlanPrice';
    id: string;
  };
};

export type CreateTalkShowMutationVariables = Exact<{
  data: TalkShowDataInput;
}>;

export type CreateTalkShowMutation = {
  __typename?: 'Mutation';
  createTalkShow: { __typename?: 'TalkShow'; id: string };
};

export type UpdateTalkShowMutationVariables = Exact<{
  id: Scalars['ID'];
  data: TalkShowDataUpdateInput;
}>;

export type UpdateTalkShowMutation = {
  __typename?: 'Mutation';
  updateTalkShow: { __typename?: 'TalkShow'; id: string };
};

export type CreateTalkShowEpisodeMutationVariables = Exact<{
  showId: Scalars['ID'];
  data: TalkShowEpisodeDataInput;
}>;

export type CreateTalkShowEpisodeMutation = {
  __typename?: 'Mutation';
  createTalkShowEpisode: { __typename?: 'TalkShowEpisode'; id: string };
};

export type UpdateTalkShowEpisodeMutationVariables = Exact<{
  id: Scalars['ID'];
  data: TalkShowEpisodeDataUpdateInput;
}>;

export type UpdateTalkShowEpisodeMutation = {
  __typename?: 'Mutation';
  updateTalkShowEpisode: { __typename?: 'TalkShowEpisode'; id: string };
};

export type AddTrendingMutationVariables = Exact<{
  data: TrendingDataInput;
}>;

export type AddTrendingMutation = {
  __typename?: 'Mutation';
  addTrending: {
    __typename?: 'Trending';
    rank: number;
    contentId: string;
    contentType: ContentType;
  };
};

export type UpdateTrendingMutationVariables = Exact<{
  id: Scalars['Int'];
  rank: Scalars['Int'];
}>;

export type UpdateTrendingMutation = {
  __typename?: 'Mutation';
  updateTrending: {
    __typename?: 'Trending';
    rank: number;
    contentId: string;
    contentType: ContentType;
  };
};

export type RemoveTrendingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveTrendingMutation = {
  __typename?: 'Mutation';
  removeTrending: boolean;
};

export type AdminQueryVariables = Exact<{ [key: string]: never }>;

export type AdminQuery = {
  __typename?: 'Query';
  admin: { __typename?: 'User'; id: string; name: string; phone: string };
};

export type ArtistsQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type ArtistsQuery = {
  __typename?: 'Query';
  artists: {
    __typename?: 'PaginatedArtists';
    count: number;
    items: Array<{
      __typename?: 'Artist';
      id: string;
      avatar?: string | null | undefined;
      name: string;
      gender?: string | null | undefined;
      bio?: string | null | undefined;
      dob?: string | null | undefined;
      address?: string | null | undefined;
    }>;
    query: {
      __typename?: 'PaginationQuery';
      take?: number | null | undefined;
      skip?: number | null | undefined;
    };
  };
};

export type ArtistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArtistQuery = {
  __typename?: 'Query';
  artist?:
    | {
        __typename?: 'Artist';
        id: string;
        avatar?: string | null | undefined;
        name: string;
        gender?: string | null | undefined;
        bio?: string | null | undefined;
        dob?: string | null | undefined;
        address?: string | null | undefined;
        tag?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ArtistSearchQueryVariables = Exact<{
  keyword: Scalars['String'];
  query: PaginationQueryInput;
}>;

export type ArtistSearchQuery = {
  __typename?: 'Query';
  artistSearch: {
    __typename?: 'PaginatedArtists';
    count: number;
    items: Array<{
      __typename?: 'Artist';
      id: string;
      avatar?: string | null | undefined;
      name: string;
    }>;
    query: {
      __typename?: 'PaginationQuery';
      take?: number | null | undefined;
      skip?: number | null | undefined;
    };
  };
};

export type GenresQueryVariables = Exact<{
  query: PaginationQueryInput;
}>;

export type GenresQuery = {
  __typename?: 'Query';
  genres: {
    __typename?: 'PaginatedGenres';
    count: number;
    items: Array<{ __typename?: 'Genre'; id: string; title: string }>;
    query: {
      __typename?: 'PaginationQuery';
      skip?: number | null | undefined;
      take?: number | null | undefined;
    };
  };
};

export type GenreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GenreQuery = {
  __typename?: 'Query';
  genre?:
    | { __typename?: 'Genre'; id: string; title: string }
    | null
    | undefined;
};

export type LatestsQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type LatestsQuery = {
  __typename?: 'Query';
  latest: {
    __typename?: 'PaginatedLatest';
    count: number;
    items: Array<{
      __typename?: 'PaginatedLatestItem';
      id: number;
      rank: number;
      item:
        | {
            __typename: 'Movie';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Music';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Series';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'TalkShow';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          };
    }>;
  };
};

export type MovieQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MovieQuery = {
  __typename?: 'Query';
  movie?:
    | {
        __typename?: 'Movie';
        id: string;
        title: string;
        description?: string | null | undefined;
        thumbnail?: string | null | undefined;
        price?: number | null | undefined;
        poster?: string | null | undefined;
        releasedDate?: any | null | undefined;
        runningTime?: number | null | undefined;
        trailer?: string | null | undefined;
        genres: Array<{ __typename?: 'Genre'; id: string; title: string }>;
        artists: Array<{
          __typename?: 'Artist';
          id: string;
          name: string;
          avatar?: string | null | undefined;
        }>;
        recommendations: Array<{
          __typename?: 'Movie';
          id: string;
          title: string;
          thumbnail?: string | null | undefined;
        }>;
        media: { __typename?: 'MediaRes'; url?: string | null | undefined };
      }
    | null
    | undefined;
};

export type MoviesQueryVariables = Exact<{
  query: PaginationQueryInput;
}>;

export type MoviesQuery = {
  __typename?: 'Query';
  movies: {
    __typename?: 'PaginatedMovies';
    count: number;
    items: Array<{
      __typename?: 'Movie';
      id: string;
      title: string;
      thumbnail?: string | null | undefined;
      description?: string | null | undefined;
    }>;
  };
};

export type MusicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MusicQuery = {
  __typename?: 'Query';
  music?:
    | {
        __typename?: 'Music';
        id: string;
        title: string;
        description?: string | null | undefined;
        thumbnail?: string | null | undefined;
        poster?: string | null | undefined;
        releasedDate?: any | null | undefined;
        runningTime?: number | null | undefined;
        trailer?: string | null | undefined;
        genres: Array<{ __typename?: 'Genre'; id: string; title: string }>;
        artists: Array<{
          __typename?: 'Artist';
          id: string;
          name: string;
          avatar?: string | null | undefined;
        }>;
        recommendations: Array<{
          __typename?: 'Music';
          id: string;
          title: string;
          thumbnail?: string | null | undefined;
        }>;
        media: { __typename?: 'MediaRes'; url?: string | null | undefined };
      }
    | null
    | undefined;
};

export type MusicsQueryVariables = Exact<{
  query: PaginationQueryInput;
}>;

export type MusicsQuery = {
  __typename?: 'Query';
  musics: {
    __typename?: 'PaginatedMusics';
    count: number;
    items: Array<{
      __typename?: 'Music';
      id: string;
      title: string;
      thumbnail?: string | null | undefined;
      description?: string | null | undefined;
    }>;
  };
};

export type SearchQueryVariables = Exact<{
  keyword: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search: Array<
    | { __typename: 'SearchArtist'; id: string; avatar: string; name: string }
    | {
        __typename: 'SearchMovie';
        id: string;
        title: string;
        thumbnail: string;
      }
    | {
        __typename: 'SearchMusic';
        id: string;
        title: string;
        thumbnail: string;
      }
    | {
        __typename: 'SearchSeries';
        id: string;
        title: string;
        thumbnail: string;
      }
    | {
        __typename: 'SearchTalkShow';
        id: string;
        title: string;
        thumbnail: string;
      }
  >;
};

export type SeriesAllQueryVariables = Exact<{
  query: PaginationQueryInput;
}>;

export type SeriesAllQuery = {
  __typename?: 'Query';
  seriesAll: {
    __typename?: 'PaginatedSeries';
    count: number;
    items: Array<{
      __typename?: 'Series';
      id: string;
      title: string;
      description?: string | null | undefined;
      thumbnail?: string | null | undefined;
    }>;
  };
};

export type SeriesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SeriesQuery = {
  __typename?: 'Query';
  series?:
    | {
        __typename?: 'Series';
        id: string;
        title: string;
        description?: string | null | undefined;
        thumbnail?: string | null | undefined;
        price?: number | null | undefined;
        poster?: string | null | undefined;
        releasedDate?: any | null | undefined;
        trailer?: string | null | undefined;
        genres: Array<{ __typename?: 'Genre'; id: string; title: string }>;
        artists: Array<{
          __typename?: 'Artist';
          id: string;
          avatar?: string | null | undefined;
          name: string;
        }>;
      }
    | null
    | undefined;
};

export type SeriesSeasonsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SeriesSeasonsQuery = {
  __typename?: 'Query';
  series?:
    | {
        __typename?: 'Series';
        id: string;
        seasons: Array<{
          __typename?: 'SeriesSeason';
          id: string;
          number: number;
        }>;
      }
    | null
    | undefined;
};

export type SeriesEpisodesQueryVariables = Exact<{
  id: Scalars['ID'];
  query?: Maybe<PaginationQueryInput>;
}>;

export type SeriesEpisodesQuery = {
  __typename?: 'Query';
  seriesEpisodes: {
    __typename?: 'PaginatedEpisodes';
    count: number;
    items: Array<{
      __typename?: 'SeriesEpisode';
      id: string;
      thumbnail?: string | null | undefined;
      number: number;
      title: string;
      description: string;
      mediaUrl?: string | null | undefined;
    }>;
    query: {
      __typename?: 'PaginationQuery';
      take?: number | null | undefined;
      skip?: number | null | undefined;
    };
  };
};

export type SlidesQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type SlidesQuery = {
  __typename?: 'Query';
  slide: {
    __typename?: 'PaginatedSlide';
    count: number;
    items: Array<{
      __typename?: 'SlideLatestItem';
      id: number;
      rank: number;
      item:
        | {
            __typename: 'Movie';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Music';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Series';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'TalkShow';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          };
    }>;
  };
};

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansQuery = {
  __typename?: 'Query';
  subscriptionPlans: Array<{
    __typename?: 'SubscriptionPlan';
    id: string;
    name: string;
    offer?: number | null | undefined;
    quality: string;
    active: boolean;
  }>;
};

export type SubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscriptionPlanQuery = {
  __typename?: 'Query';
  subscriptionPlan?:
    | {
        __typename?: 'SubscriptionPlan';
        id: string;
        background: boolean;
        originals: boolean;
        active: boolean;
        name: string;
        offer?: number | null | undefined;
        pip: boolean;
        coin: boolean;
        ads: boolean;
        tvod: boolean;
        download: boolean;
        quality: string;
        prices: Array<{
          __typename?: 'SubscriptionPlanPrice';
          id: string;
          price: number;
          name: string;
          time: string;
          active: boolean;
        }>;
      }
    | null
    | undefined;
};

export type TalkShowsQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type TalkShowsQuery = {
  __typename?: 'Query';
  talkShowAll: {
    __typename?: 'PaginatedTalkShow';
    count: number;
    items: Array<{
      __typename?: 'TalkShow';
      id: string;
      thumbnail?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
    }>;
  };
};

export type TalkShowQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TalkShowQuery = {
  __typename?: 'Query';
  talkShow?:
    | {
        __typename?: 'TalkShow';
        id: string;
        thumbnail?: string | null | undefined;
        title: string;
        description?: string | null | undefined;
        poster?: string | null | undefined;
        trailer?: string | null | undefined;
        releasedDate?: any | null | undefined;
        artists: Array<{
          __typename?: 'Artist';
          id: string;
          avatar?: string | null | undefined;
          name: string;
        }>;
      }
    | null
    | undefined;
};

export type TalkShowEpisodesQueryVariables = Exact<{
  showId: Scalars['ID'];
  query?: Maybe<PaginationQueryInput>;
}>;

export type TalkShowEpisodesQuery = {
  __typename?: 'Query';
  talkShowEpisodes: {
    __typename?: 'PaginatedTalkShowEpisode';
    count: number;
    items: Array<{
      __typename?: 'TalkShowEpisode';
      id: string;
      thumbnail: string;
      number: number;
      title: string;
      description: string;
      mediaUrl?: string | null | undefined;
    }>;
  };
};

export type TrendingsQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type TrendingsQuery = {
  __typename?: 'Query';
  trending: {
    __typename?: 'PaginatedTrending';
    count: number;
    items: Array<{
      __typename?: 'PaginatedTrendingItem';
      id: number;
      rank: number;
      item:
        | {
            __typename: 'Movie';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Music';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'Series';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          }
        | {
            __typename: 'TalkShow';
            id: string;
            title: string;
            thumbnail?: string | null | undefined;
          };
    }>;
  };
};

export type UsersQueryVariables = Exact<{
  query?: Maybe<PaginationQueryInput>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUsers';
    count: number;
    items: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      phone: string;
    }>;
    query: {
      __typename?: 'PaginationQuery';
      take?: number | null | undefined;
      skip?: number | null | undefined;
    };
  };
};

export const CreateArtistDocument = `
    mutation createArtist($data: ArtistDataInput!) {
  createArtist(data: $data) {
    id
    avatar
    name
    gender
    bio
    dob
    address
    tag
  }
}
    `;
export const useCreateArtistMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateArtistMutation,
    TError,
    CreateArtistMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateArtistMutation,
    TError,
    CreateArtistMutationVariables,
    TContext
  >(
    ['createArtist'],
    (variables?: CreateArtistMutationVariables) =>
      fetchData<CreateArtistMutation, CreateArtistMutationVariables>(
        CreateArtistDocument,
        variables
      )(),
    options
  );
export const UpdateArtistDocument = `
    mutation updateArtist($id: ID!, $data: ArtistDataUpdateInput!) {
  updateArtist(data: $data, id: $id) {
    id
    avatar
    name
    gender
    bio
    dob
    address
    tag
  }
}
    `;
export const useUpdateArtistMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateArtistMutation,
    TError,
    UpdateArtistMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateArtistMutation,
    TError,
    UpdateArtistMutationVariables,
    TContext
  >(
    ['updateArtist'],
    (variables?: UpdateArtistMutationVariables) =>
      fetchData<UpdateArtistMutation, UpdateArtistMutationVariables>(
        UpdateArtistDocument,
        variables
      )(),
    options
  );
export const LogInDocument = `
    mutation logIn($data: AuthLogInInput!) {
  authLogIn(data: $data) {
    jwt
    refreshToken
  }
}
    `;
export const useLogInMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogInMutation,
    TError,
    LogInMutationVariables,
    TContext
  >
) =>
  useMutation<LogInMutation, TError, LogInMutationVariables, TContext>(
    ['logIn'],
    (variables?: LogInMutationVariables) =>
      fetchData<LogInMutation, LogInMutationVariables>(
        LogInDocument,
        variables
      )(),
    options
  );
export const LogOutDocument = `
    mutation logOut {
  authLogOut
}
    `;
export const useLogOutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogOutMutation,
    TError,
    LogOutMutationVariables,
    TContext
  >
) =>
  useMutation<LogOutMutation, TError, LogOutMutationVariables, TContext>(
    ['logOut'],
    (variables?: LogOutMutationVariables) =>
      fetchData<LogOutMutation, LogOutMutationVariables>(
        LogOutDocument,
        variables
      )(),
    options
  );
export const RefreshTokenDocument = `
    mutation refreshToken($refreshToken: String!) {
  authTokenRefresh(refreshToken: $refreshToken) {
    jwt
    refreshToken
  }
}
    `;
export const useRefreshTokenMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RefreshTokenMutation,
    TError,
    RefreshTokenMutationVariables,
    TContext
  >
) =>
  useMutation<
    RefreshTokenMutation,
    TError,
    RefreshTokenMutationVariables,
    TContext
  >(
    ['refreshToken'],
    (variables?: RefreshTokenMutationVariables) =>
      fetchData<RefreshTokenMutation, RefreshTokenMutationVariables>(
        RefreshTokenDocument,
        variables
      )(),
    options
  );
export const CreateGenreDocument = `
    mutation createGenre($data: GenreDataInput!) {
  createGenre(data: $data) {
    id
    title
  }
}
    `;
export const useCreateGenreMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateGenreMutation,
    TError,
    CreateGenreMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateGenreMutation,
    TError,
    CreateGenreMutationVariables,
    TContext
  >(
    ['createGenre'],
    (variables?: CreateGenreMutationVariables) =>
      fetchData<CreateGenreMutation, CreateGenreMutationVariables>(
        CreateGenreDocument,
        variables
      )(),
    options
  );
export const UpdateGenreDocument = `
    mutation updateGenre($id: ID!, $data: GenreDataUpdateInput!) {
  updateGenre(id: $id, data: $data) {
    id
    title
  }
}
    `;
export const useUpdateGenreMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateGenreMutation,
    TError,
    UpdateGenreMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateGenreMutation,
    TError,
    UpdateGenreMutationVariables,
    TContext
  >(
    ['updateGenre'],
    (variables?: UpdateGenreMutationVariables) =>
      fetchData<UpdateGenreMutation, UpdateGenreMutationVariables>(
        UpdateGenreDocument,
        variables
      )(),
    options
  );
export const AddLatestDocument = `
    mutation addLatest($data: LatestDataInput!) {
  addLatest(data: $data) {
    contentType
    contentId
    rank
  }
}
    `;
export const useAddLatestMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddLatestMutation,
    TError,
    AddLatestMutationVariables,
    TContext
  >
) =>
  useMutation<AddLatestMutation, TError, AddLatestMutationVariables, TContext>(
    ['addLatest'],
    (variables?: AddLatestMutationVariables) =>
      fetchData<AddLatestMutation, AddLatestMutationVariables>(
        AddLatestDocument,
        variables
      )(),
    options
  );
export const UpdateLatestDocument = `
    mutation updateLatest($id: Int!, $rank: Int!) {
  updateLatest(rank: $rank, id: $id) {
    contentType
    contentId
    rank
  }
}
    `;
export const useUpdateLatestMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateLatestMutation,
    TError,
    UpdateLatestMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateLatestMutation,
    TError,
    UpdateLatestMutationVariables,
    TContext
  >(
    ['updateLatest'],
    (variables?: UpdateLatestMutationVariables) =>
      fetchData<UpdateLatestMutation, UpdateLatestMutationVariables>(
        UpdateLatestDocument,
        variables
      )(),
    options
  );
export const RemoveLatestDocument = `
    mutation removeLatest($id: Int!) {
  removeLatest(id: $id)
}
    `;
export const useRemoveLatestMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveLatestMutation,
    TError,
    RemoveLatestMutationVariables,
    TContext
  >
) =>
  useMutation<
    RemoveLatestMutation,
    TError,
    RemoveLatestMutationVariables,
    TContext
  >(
    ['removeLatest'],
    (variables?: RemoveLatestMutationVariables) =>
      fetchData<RemoveLatestMutation, RemoveLatestMutationVariables>(
        RemoveLatestDocument,
        variables
      )(),
    options
  );
export const CreateMovieDocument = `
    mutation createMovie($data: MovieDataInput!) {
  createMovie(data: $data) {
    id
  }
}
    `;
export const useCreateMovieMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateMovieMutation,
    TError,
    CreateMovieMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateMovieMutation,
    TError,
    CreateMovieMutationVariables,
    TContext
  >(
    ['createMovie'],
    (variables?: CreateMovieMutationVariables) =>
      fetchData<CreateMovieMutation, CreateMovieMutationVariables>(
        CreateMovieDocument,
        variables
      )(),
    options
  );
export const UpdateMovieDocument = `
    mutation updateMovie($id: ID!, $data: MovieDataUpdateInput!) {
  updateMovie(id: $id, data: $data) {
    id
  }
}
    `;
export const useUpdateMovieMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMovieMutation,
    TError,
    UpdateMovieMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateMovieMutation,
    TError,
    UpdateMovieMutationVariables,
    TContext
  >(
    ['updateMovie'],
    (variables?: UpdateMovieMutationVariables) =>
      fetchData<UpdateMovieMutation, UpdateMovieMutationVariables>(
        UpdateMovieDocument,
        variables
      )(),
    options
  );
export const CreateMusicDocument = `
    mutation createMusic($data: MusicDataInput!) {
  createMusic(data: $data) {
    id
  }
}
    `;
export const useCreateMusicMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateMusicMutation,
    TError,
    CreateMusicMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateMusicMutation,
    TError,
    CreateMusicMutationVariables,
    TContext
  >(
    ['createMusic'],
    (variables?: CreateMusicMutationVariables) =>
      fetchData<CreateMusicMutation, CreateMusicMutationVariables>(
        CreateMusicDocument,
        variables
      )(),
    options
  );
export const UpdateMusicDocument = `
    mutation updateMusic($id: ID!, $data: MusicDataUpdateInput!) {
  updateMusic(id: $id, data: $data) {
    id
  }
}
    `;
export const useUpdateMusicMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMusicMutation,
    TError,
    UpdateMusicMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateMusicMutation,
    TError,
    UpdateMusicMutationVariables,
    TContext
  >(
    ['updateMusic'],
    (variables?: UpdateMusicMutationVariables) =>
      fetchData<UpdateMusicMutation, UpdateMusicMutationVariables>(
        UpdateMusicDocument,
        variables
      )(),
    options
  );
export const CreateSeriesDocument = `
    mutation createSeries($data: SeriesDataInput!) {
  createSeries(data: $data) {
    id
  }
}
    `;
export const useCreateSeriesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateSeriesMutation,
    TError,
    CreateSeriesMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateSeriesMutation,
    TError,
    CreateSeriesMutationVariables,
    TContext
  >(
    ['createSeries'],
    (variables?: CreateSeriesMutationVariables) =>
      fetchData<CreateSeriesMutation, CreateSeriesMutationVariables>(
        CreateSeriesDocument,
        variables
      )(),
    options
  );
export const UpdateSeriesDocument = `
    mutation updateSeries($id: ID!, $data: SeriesDataUpdateInput!) {
  updateSeries(id: $id, data: $data) {
    id
  }
}
    `;
export const useUpdateSeriesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSeriesMutation,
    TError,
    UpdateSeriesMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSeriesMutation,
    TError,
    UpdateSeriesMutationVariables,
    TContext
  >(
    ['updateSeries'],
    (variables?: UpdateSeriesMutationVariables) =>
      fetchData<UpdateSeriesMutation, UpdateSeriesMutationVariables>(
        UpdateSeriesDocument,
        variables
      )(),
    options
  );
export const CreateSeriesSeasonDocument = `
    mutation createSeriesSeason($number: Int!, $seriesId: String!) {
  createSeriesSeason(data: {number: $number, series: $seriesId}) {
    id
    number
  }
}
    `;
export const useCreateSeriesSeasonMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateSeriesSeasonMutation,
    TError,
    CreateSeriesSeasonMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateSeriesSeasonMutation,
    TError,
    CreateSeriesSeasonMutationVariables,
    TContext
  >(
    ['createSeriesSeason'],
    (variables?: CreateSeriesSeasonMutationVariables) =>
      fetchData<
        CreateSeriesSeasonMutation,
        CreateSeriesSeasonMutationVariables
      >(CreateSeriesSeasonDocument, variables)(),
    options
  );
export const CreateSeriesEpisodeDocument = `
    mutation createSeriesEpisode($seasonId: ID!, $data: SeriesEpisodeDataInput!) {
  createSeriesEpisode(seasonId: $seasonId, data: $data) {
    id
  }
}
    `;
export const useCreateSeriesEpisodeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateSeriesEpisodeMutation,
    TError,
    CreateSeriesEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateSeriesEpisodeMutation,
    TError,
    CreateSeriesEpisodeMutationVariables,
    TContext
  >(
    ['createSeriesEpisode'],
    (variables?: CreateSeriesEpisodeMutationVariables) =>
      fetchData<
        CreateSeriesEpisodeMutation,
        CreateSeriesEpisodeMutationVariables
      >(CreateSeriesEpisodeDocument, variables)(),
    options
  );
export const UpdateSeriesEpisodeDocument = `
    mutation updateSeriesEpisode($id: ID!, $data: SeriesEpisodeDataUpdateInput!) {
  updateSeriesEpisode(data: $data, id: $id) {
    id
  }
}
    `;
export const useUpdateSeriesEpisodeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateSeriesEpisodeMutation,
    TError,
    UpdateSeriesEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSeriesEpisodeMutation,
    TError,
    UpdateSeriesEpisodeMutationVariables,
    TContext
  >(
    ['updateSeriesEpisode'],
    (variables?: UpdateSeriesEpisodeMutationVariables) =>
      fetchData<
        UpdateSeriesEpisodeMutation,
        UpdateSeriesEpisodeMutationVariables
      >(UpdateSeriesEpisodeDocument, variables)(),
    options
  );
export const AddSlideDocument = `
    mutation addSlide($data: SlideDataInput!) {
  addSlide(data: $data) {
    rank
    contentType
    contentId
  }
}
    `;
export const useAddSlideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddSlideMutation,
    TError,
    AddSlideMutationVariables,
    TContext
  >
) =>
  useMutation<AddSlideMutation, TError, AddSlideMutationVariables, TContext>(
    ['addSlide'],
    (variables?: AddSlideMutationVariables) =>
      fetchData<AddSlideMutation, AddSlideMutationVariables>(
        AddSlideDocument,
        variables
      )(),
    options
  );
export const UpdateSlideDocument = `
    mutation updateSlide($id: Int!, $rank: Int!) {
  updateSlide(id: $id, rank: $rank) {
    rank
    contentType
    contentId
  }
}
    `;
export const useUpdateSlideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSlideMutation,
    TError,
    UpdateSlideMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSlideMutation,
    TError,
    UpdateSlideMutationVariables,
    TContext
  >(
    ['updateSlide'],
    (variables?: UpdateSlideMutationVariables) =>
      fetchData<UpdateSlideMutation, UpdateSlideMutationVariables>(
        UpdateSlideDocument,
        variables
      )(),
    options
  );
export const RemoveSlideDocument = `
    mutation removeSlide($id: Int!) {
  removeSlide(id: $id)
}
    `;
export const useRemoveSlideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveSlideMutation,
    TError,
    RemoveSlideMutationVariables,
    TContext
  >
) =>
  useMutation<
    RemoveSlideMutation,
    TError,
    RemoveSlideMutationVariables,
    TContext
  >(
    ['removeSlide'],
    (variables?: RemoveSlideMutationVariables) =>
      fetchData<RemoveSlideMutation, RemoveSlideMutationVariables>(
        RemoveSlideDocument,
        variables
      )(),
    options
  );
export const CreateSubscriptionPlanDocument = `
    mutation createSubscriptionPlan($data: SubscriptionPlanDataInput!) {
  createSubscriptionPlan(data: $data) {
    id
  }
}
    `;
export const useCreateSubscriptionPlanMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateSubscriptionPlanMutation,
    TError,
    CreateSubscriptionPlanMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateSubscriptionPlanMutation,
    TError,
    CreateSubscriptionPlanMutationVariables,
    TContext
  >(
    ['createSubscriptionPlan'],
    (variables?: CreateSubscriptionPlanMutationVariables) =>
      fetchData<
        CreateSubscriptionPlanMutation,
        CreateSubscriptionPlanMutationVariables
      >(CreateSubscriptionPlanDocument, variables)(),
    options
  );
export const UpdateSubscriptionPlanDocument = `
    mutation updateSubscriptionPlan($id: ID!, $data: SubscriptionPlanDataUpdateInput!) {
  updateSubscriptionPlan(data: $data, id: $id) {
    id
  }
}
    `;
export const useUpdateSubscriptionPlanMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateSubscriptionPlanMutation,
    TError,
    UpdateSubscriptionPlanMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSubscriptionPlanMutation,
    TError,
    UpdateSubscriptionPlanMutationVariables,
    TContext
  >(
    ['updateSubscriptionPlan'],
    (variables?: UpdateSubscriptionPlanMutationVariables) =>
      fetchData<
        UpdateSubscriptionPlanMutation,
        UpdateSubscriptionPlanMutationVariables
      >(UpdateSubscriptionPlanDocument, variables)(),
    options
  );
export const CreateSubscriptionPlanPriceDocument = `
    mutation createSubscriptionPlanPrice($data: SubscriptionPlanPriceDataInput!, $id: ID!) {
  createSubscriptionPlanPrice(data: $data, id: $id) {
    id
  }
}
    `;
export const useCreateSubscriptionPlanPriceMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateSubscriptionPlanPriceMutation,
    TError,
    CreateSubscriptionPlanPriceMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateSubscriptionPlanPriceMutation,
    TError,
    CreateSubscriptionPlanPriceMutationVariables,
    TContext
  >(
    ['createSubscriptionPlanPrice'],
    (variables?: CreateSubscriptionPlanPriceMutationVariables) =>
      fetchData<
        CreateSubscriptionPlanPriceMutation,
        CreateSubscriptionPlanPriceMutationVariables
      >(CreateSubscriptionPlanPriceDocument, variables)(),
    options
  );
export const UpdateSubscriptionPlanPriceDocument = `
    mutation updateSubscriptionPlanPrice($id: ID!, $data: SubscriptionPlanPriceDataUpdateInput!) {
  updateSubscriptionPlanPrice(data: $data, id: $id) {
    id
  }
}
    `;
export const useUpdateSubscriptionPlanPriceMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateSubscriptionPlanPriceMutation,
    TError,
    UpdateSubscriptionPlanPriceMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateSubscriptionPlanPriceMutation,
    TError,
    UpdateSubscriptionPlanPriceMutationVariables,
    TContext
  >(
    ['updateSubscriptionPlanPrice'],
    (variables?: UpdateSubscriptionPlanPriceMutationVariables) =>
      fetchData<
        UpdateSubscriptionPlanPriceMutation,
        UpdateSubscriptionPlanPriceMutationVariables
      >(UpdateSubscriptionPlanPriceDocument, variables)(),
    options
  );
export const CreateTalkShowDocument = `
    mutation createTalkShow($data: TalkShowDataInput!) {
  createTalkShow(data: $data) {
    id
  }
}
    `;
export const useCreateTalkShowMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateTalkShowMutation,
    TError,
    CreateTalkShowMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateTalkShowMutation,
    TError,
    CreateTalkShowMutationVariables,
    TContext
  >(
    ['createTalkShow'],
    (variables?: CreateTalkShowMutationVariables) =>
      fetchData<CreateTalkShowMutation, CreateTalkShowMutationVariables>(
        CreateTalkShowDocument,
        variables
      )(),
    options
  );
export const UpdateTalkShowDocument = `
    mutation updateTalkShow($id: ID!, $data: TalkShowDataUpdateInput!) {
  updateTalkShow(data: $data, id: $id) {
    id
  }
}
    `;
export const useUpdateTalkShowMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateTalkShowMutation,
    TError,
    UpdateTalkShowMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateTalkShowMutation,
    TError,
    UpdateTalkShowMutationVariables,
    TContext
  >(
    ['updateTalkShow'],
    (variables?: UpdateTalkShowMutationVariables) =>
      fetchData<UpdateTalkShowMutation, UpdateTalkShowMutationVariables>(
        UpdateTalkShowDocument,
        variables
      )(),
    options
  );
export const CreateTalkShowEpisodeDocument = `
    mutation createTalkShowEpisode($showId: ID!, $data: TalkShowEpisodeDataInput!) {
  createTalkShowEpisode(data: $data, showId: $showId) {
    id
  }
}
    `;
export const useCreateTalkShowEpisodeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateTalkShowEpisodeMutation,
    TError,
    CreateTalkShowEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateTalkShowEpisodeMutation,
    TError,
    CreateTalkShowEpisodeMutationVariables,
    TContext
  >(
    ['createTalkShowEpisode'],
    (variables?: CreateTalkShowEpisodeMutationVariables) =>
      fetchData<
        CreateTalkShowEpisodeMutation,
        CreateTalkShowEpisodeMutationVariables
      >(CreateTalkShowEpisodeDocument, variables)(),
    options
  );
export const UpdateTalkShowEpisodeDocument = `
    mutation updateTalkShowEpisode($id: ID!, $data: TalkShowEpisodeDataUpdateInput!) {
  updateTalkShowEpisode(data: $data, id: $id) {
    id
  }
}
    `;
export const useUpdateTalkShowEpisodeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateTalkShowEpisodeMutation,
    TError,
    UpdateTalkShowEpisodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateTalkShowEpisodeMutation,
    TError,
    UpdateTalkShowEpisodeMutationVariables,
    TContext
  >(
    ['updateTalkShowEpisode'],
    (variables?: UpdateTalkShowEpisodeMutationVariables) =>
      fetchData<
        UpdateTalkShowEpisodeMutation,
        UpdateTalkShowEpisodeMutationVariables
      >(UpdateTalkShowEpisodeDocument, variables)(),
    options
  );
export const AddTrendingDocument = `
    mutation addTrending($data: TrendingDataInput!) {
  addTrending(data: $data) {
    rank
    contentId
    contentType
  }
}
    `;
export const useAddTrendingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddTrendingMutation,
    TError,
    AddTrendingMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddTrendingMutation,
    TError,
    AddTrendingMutationVariables,
    TContext
  >(
    ['addTrending'],
    (variables?: AddTrendingMutationVariables) =>
      fetchData<AddTrendingMutation, AddTrendingMutationVariables>(
        AddTrendingDocument,
        variables
      )(),
    options
  );
export const UpdateTrendingDocument = `
    mutation updateTrending($id: Int!, $rank: Int!) {
  updateTrending(rank: $rank, id: $id) {
    rank
    contentId
    contentType
  }
}
    `;
export const useUpdateTrendingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateTrendingMutation,
    TError,
    UpdateTrendingMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateTrendingMutation,
    TError,
    UpdateTrendingMutationVariables,
    TContext
  >(
    ['updateTrending'],
    (variables?: UpdateTrendingMutationVariables) =>
      fetchData<UpdateTrendingMutation, UpdateTrendingMutationVariables>(
        UpdateTrendingDocument,
        variables
      )(),
    options
  );
export const RemoveTrendingDocument = `
    mutation removeTrending($id: Int!) {
  removeTrending(id: $id)
}
    `;
export const useRemoveTrendingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveTrendingMutation,
    TError,
    RemoveTrendingMutationVariables,
    TContext
  >
) =>
  useMutation<
    RemoveTrendingMutation,
    TError,
    RemoveTrendingMutationVariables,
    TContext
  >(
    ['removeTrending'],
    (variables?: RemoveTrendingMutationVariables) =>
      fetchData<RemoveTrendingMutation, RemoveTrendingMutationVariables>(
        RemoveTrendingDocument,
        variables
      )(),
    options
  );
export const AdminDocument = `
    query admin {
  admin {
    id
    name
    phone
  }
}
    `;
export const useAdminQuery = <TData = AdminQuery, TError = unknown>(
  variables?: AdminQueryVariables,
  options?: UseQueryOptions<AdminQuery, TError, TData>
) =>
  useQuery<AdminQuery, TError, TData>(
    variables === undefined ? ['admin'] : ['admin', variables],
    fetchData<AdminQuery, AdminQueryVariables>(AdminDocument, variables),
    options
  );

useAdminQuery.getKey = (variables?: AdminQueryVariables) =>
  variables === undefined ? ['admin'] : ['admin', variables];
export const ArtistsDocument = `
    query artists($query: PaginationQueryInput) {
  artists(query: $query) {
    items {
      id
      avatar
      name
      gender
      bio
      dob
      address
    }
    count
    query {
      take
      skip
    }
  }
}
    `;
export const useArtistsQuery = <TData = ArtistsQuery, TError = unknown>(
  variables?: ArtistsQueryVariables,
  options?: UseQueryOptions<ArtistsQuery, TError, TData>
) =>
  useQuery<ArtistsQuery, TError, TData>(
    variables === undefined ? ['artists'] : ['artists', variables],
    fetchData<ArtistsQuery, ArtistsQueryVariables>(ArtistsDocument, variables),
    options
  );

useArtistsQuery.getKey = (variables?: ArtistsQueryVariables) =>
  variables === undefined ? ['artists'] : ['artists', variables];
export const ArtistDocument = `
    query artist($id: ID!) {
  artist(id: $id) {
    id
    avatar
    name
    gender
    bio
    dob
    address
    tag
  }
}
    `;
export const useArtistQuery = <TData = ArtistQuery, TError = unknown>(
  variables: ArtistQueryVariables,
  options?: UseQueryOptions<ArtistQuery, TError, TData>
) =>
  useQuery<ArtistQuery, TError, TData>(
    ['artist', variables],
    fetchData<ArtistQuery, ArtistQueryVariables>(ArtistDocument, variables),
    options
  );

useArtistQuery.getKey = (variables: ArtistQueryVariables) => [
  'artist',
  variables
];
export const ArtistSearchDocument = `
    query artistSearch($keyword: String!, $query: PaginationQueryInput!) {
  artistSearch(keyword: $keyword, query: $query) {
    items {
      id
      avatar
      name
    }
    count
    query {
      take
      skip
    }
  }
}
    `;
export const useArtistSearchQuery = <
  TData = ArtistSearchQuery,
  TError = unknown
>(
  variables: ArtistSearchQueryVariables,
  options?: UseQueryOptions<ArtistSearchQuery, TError, TData>
) =>
  useQuery<ArtistSearchQuery, TError, TData>(
    ['artistSearch', variables],
    fetchData<ArtistSearchQuery, ArtistSearchQueryVariables>(
      ArtistSearchDocument,
      variables
    ),
    options
  );

useArtistSearchQuery.getKey = (variables: ArtistSearchQueryVariables) => [
  'artistSearch',
  variables
];
export const GenresDocument = `
    query genres($query: PaginationQueryInput!) {
  genres(query: $query) {
    items {
      id
      title
    }
    count
    query {
      skip
      take
    }
  }
}
    `;
export const useGenresQuery = <TData = GenresQuery, TError = unknown>(
  variables: GenresQueryVariables,
  options?: UseQueryOptions<GenresQuery, TError, TData>
) =>
  useQuery<GenresQuery, TError, TData>(
    ['genres', variables],
    fetchData<GenresQuery, GenresQueryVariables>(GenresDocument, variables),
    options
  );

useGenresQuery.getKey = (variables: GenresQueryVariables) => [
  'genres',
  variables
];
export const GenreDocument = `
    query genre($id: ID!) {
  genre(id: $id) {
    id
    title
  }
}
    `;
export const useGenreQuery = <TData = GenreQuery, TError = unknown>(
  variables: GenreQueryVariables,
  options?: UseQueryOptions<GenreQuery, TError, TData>
) =>
  useQuery<GenreQuery, TError, TData>(
    ['genre', variables],
    fetchData<GenreQuery, GenreQueryVariables>(GenreDocument, variables),
    options
  );

useGenreQuery.getKey = (variables: GenreQueryVariables) => ['genre', variables];
export const LatestsDocument = `
    query latests($query: PaginationQueryInput) {
  latest(query: $query) {
    items {
      id
      rank
      item {
        __typename
        ... on Movie {
          id
          title
          thumbnail
        }
        ... on Series {
          id
          title
          thumbnail
        }
        ... on Music {
          id
          title
          thumbnail
        }
        ... on TalkShow {
          id
          title
          thumbnail
        }
      }
    }
    count
  }
}
    `;
export const useLatestsQuery = <TData = LatestsQuery, TError = unknown>(
  variables?: LatestsQueryVariables,
  options?: UseQueryOptions<LatestsQuery, TError, TData>
) =>
  useQuery<LatestsQuery, TError, TData>(
    variables === undefined ? ['latests'] : ['latests', variables],
    fetchData<LatestsQuery, LatestsQueryVariables>(LatestsDocument, variables),
    options
  );

useLatestsQuery.getKey = (variables?: LatestsQueryVariables) =>
  variables === undefined ? ['latests'] : ['latests', variables];
export const MovieDocument = `
    query movie($id: ID!) {
  movie(id: $id) {
    id
    title
    description
    thumbnail
    genres {
      id
      title
    }
    artists {
      id
      name
      avatar
    }
    price
    poster
    releasedDate
    runningTime
    trailer
    recommendations {
      id
      title
      thumbnail
    }
    media {
      url
    }
  }
}
    `;
export const useMovieQuery = <TData = MovieQuery, TError = unknown>(
  variables: MovieQueryVariables,
  options?: UseQueryOptions<MovieQuery, TError, TData>
) =>
  useQuery<MovieQuery, TError, TData>(
    ['movie', variables],
    fetchData<MovieQuery, MovieQueryVariables>(MovieDocument, variables),
    options
  );

useMovieQuery.getKey = (variables: MovieQueryVariables) => ['movie', variables];
export const MoviesDocument = `
    query movies($query: PaginationQueryInput!) {
  movies(query: $query) {
    items {
      id
      title
      thumbnail
      description
    }
    count
  }
}
    `;
export const useMoviesQuery = <TData = MoviesQuery, TError = unknown>(
  variables: MoviesQueryVariables,
  options?: UseQueryOptions<MoviesQuery, TError, TData>
) =>
  useQuery<MoviesQuery, TError, TData>(
    ['movies', variables],
    fetchData<MoviesQuery, MoviesQueryVariables>(MoviesDocument, variables),
    options
  );

useMoviesQuery.getKey = (variables: MoviesQueryVariables) => [
  'movies',
  variables
];
export const MusicDocument = `
    query music($id: ID!) {
  music(id: $id) {
    id
    title
    description
    thumbnail
    genres {
      id
      title
    }
    artists {
      id
      name
      avatar
    }
    recommendations {
      id
      title
      thumbnail
    }
    poster
    releasedDate
    runningTime
    trailer
    media {
      url
    }
  }
}
    `;
export const useMusicQuery = <TData = MusicQuery, TError = unknown>(
  variables: MusicQueryVariables,
  options?: UseQueryOptions<MusicQuery, TError, TData>
) =>
  useQuery<MusicQuery, TError, TData>(
    ['music', variables],
    fetchData<MusicQuery, MusicQueryVariables>(MusicDocument, variables),
    options
  );

useMusicQuery.getKey = (variables: MusicQueryVariables) => ['music', variables];
export const MusicsDocument = `
    query musics($query: PaginationQueryInput!) {
  musics(query: $query) {
    items {
      id
      title
      thumbnail
      description
    }
    count
  }
}
    `;
export const useMusicsQuery = <TData = MusicsQuery, TError = unknown>(
  variables: MusicsQueryVariables,
  options?: UseQueryOptions<MusicsQuery, TError, TData>
) =>
  useQuery<MusicsQuery, TError, TData>(
    ['musics', variables],
    fetchData<MusicsQuery, MusicsQueryVariables>(MusicsDocument, variables),
    options
  );

useMusicsQuery.getKey = (variables: MusicsQueryVariables) => [
  'musics',
  variables
];
export const SearchDocument = `
    query search($keyword: String!, $filter: String) {
  search(keyword: $keyword, filter: $filter) {
    __typename
    ... on SearchMovie {
      id
      title
      thumbnail
    }
    ... on SearchSeries {
      id
      title
      thumbnail
    }
    ... on SearchArtist {
      id
      avatar
      name
    }
    ... on SearchMusic {
      id
      title
      thumbnail
    }
    ... on SearchTalkShow {
      id
      title
      thumbnail
    }
  }
}
    `;
export const useSearchQuery = <TData = SearchQuery, TError = unknown>(
  variables: SearchQueryVariables,
  options?: UseQueryOptions<SearchQuery, TError, TData>
) =>
  useQuery<SearchQuery, TError, TData>(
    ['search', variables],
    fetchData<SearchQuery, SearchQueryVariables>(SearchDocument, variables),
    options
  );

useSearchQuery.getKey = (variables: SearchQueryVariables) => [
  'search',
  variables
];
export const SeriesAllDocument = `
    query seriesAll($query: PaginationQueryInput!) {
  seriesAll(query: $query) {
    items {
      id
      title
      description
      thumbnail
    }
    count
  }
}
    `;
export const useSeriesAllQuery = <TData = SeriesAllQuery, TError = unknown>(
  variables: SeriesAllQueryVariables,
  options?: UseQueryOptions<SeriesAllQuery, TError, TData>
) =>
  useQuery<SeriesAllQuery, TError, TData>(
    ['seriesAll', variables],
    fetchData<SeriesAllQuery, SeriesAllQueryVariables>(
      SeriesAllDocument,
      variables
    ),
    options
  );

useSeriesAllQuery.getKey = (variables: SeriesAllQueryVariables) => [
  'seriesAll',
  variables
];
export const SeriesDocument = `
    query series($id: ID!) {
  series(id: $id) {
    id
    title
    description
    thumbnail
    genres {
      id
      title
    }
    artists {
      id
      avatar
      name
    }
    price
    poster
    releasedDate
    trailer
  }
}
    `;
export const useSeriesQuery = <TData = SeriesQuery, TError = unknown>(
  variables: SeriesQueryVariables,
  options?: UseQueryOptions<SeriesQuery, TError, TData>
) =>
  useQuery<SeriesQuery, TError, TData>(
    ['series', variables],
    fetchData<SeriesQuery, SeriesQueryVariables>(SeriesDocument, variables),
    options
  );

useSeriesQuery.getKey = (variables: SeriesQueryVariables) => [
  'series',
  variables
];
export const SeriesSeasonsDocument = `
    query seriesSeasons($id: ID!) {
  series(id: $id) {
    id
    seasons {
      id
      number
    }
  }
}
    `;
export const useSeriesSeasonsQuery = <
  TData = SeriesSeasonsQuery,
  TError = unknown
>(
  variables: SeriesSeasonsQueryVariables,
  options?: UseQueryOptions<SeriesSeasonsQuery, TError, TData>
) =>
  useQuery<SeriesSeasonsQuery, TError, TData>(
    ['seriesSeasons', variables],
    fetchData<SeriesSeasonsQuery, SeriesSeasonsQueryVariables>(
      SeriesSeasonsDocument,
      variables
    ),
    options
  );

useSeriesSeasonsQuery.getKey = (variables: SeriesSeasonsQueryVariables) => [
  'seriesSeasons',
  variables
];
export const SeriesEpisodesDocument = `
    query seriesEpisodes($id: ID!, $query: PaginationQueryInput) {
  seriesEpisodes(seasonId: $id, query: $query) {
    items {
      id
      thumbnail
      number
      title
      description
      mediaUrl
    }
    count
    query {
      take
      skip
    }
  }
}
    `;
export const useSeriesEpisodesQuery = <
  TData = SeriesEpisodesQuery,
  TError = unknown
>(
  variables: SeriesEpisodesQueryVariables,
  options?: UseQueryOptions<SeriesEpisodesQuery, TError, TData>
) =>
  useQuery<SeriesEpisodesQuery, TError, TData>(
    ['seriesEpisodes', variables],
    fetchData<SeriesEpisodesQuery, SeriesEpisodesQueryVariables>(
      SeriesEpisodesDocument,
      variables
    ),
    options
  );

useSeriesEpisodesQuery.getKey = (variables: SeriesEpisodesQueryVariables) => [
  'seriesEpisodes',
  variables
];
export const SlidesDocument = `
    query slides($query: PaginationQueryInput) {
  slide(query: $query) {
    items {
      id
      rank
      item {
        __typename
        ... on Movie {
          id
          title
          thumbnail
        }
        ... on Series {
          id
          title
          thumbnail
        }
        ... on Music {
          id
          title
          thumbnail
        }
        ... on TalkShow {
          id
          title
          thumbnail
        }
      }
    }
    count
  }
}
    `;
export const useSlidesQuery = <TData = SlidesQuery, TError = unknown>(
  variables?: SlidesQueryVariables,
  options?: UseQueryOptions<SlidesQuery, TError, TData>
) =>
  useQuery<SlidesQuery, TError, TData>(
    variables === undefined ? ['slides'] : ['slides', variables],
    fetchData<SlidesQuery, SlidesQueryVariables>(SlidesDocument, variables),
    options
  );

useSlidesQuery.getKey = (variables?: SlidesQueryVariables) =>
  variables === undefined ? ['slides'] : ['slides', variables];
export const SubscriptionPlansDocument = `
    query subscriptionPlans {
  subscriptionPlans {
    id
    name
    offer
    quality
    active
  }
}
    `;
export const useSubscriptionPlansQuery = <
  TData = SubscriptionPlansQuery,
  TError = unknown
>(
  variables?: SubscriptionPlansQueryVariables,
  options?: UseQueryOptions<SubscriptionPlansQuery, TError, TData>
) =>
  useQuery<SubscriptionPlansQuery, TError, TData>(
    variables === undefined
      ? ['subscriptionPlans']
      : ['subscriptionPlans', variables],
    fetchData<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
      SubscriptionPlansDocument,
      variables
    ),
    options
  );

useSubscriptionPlansQuery.getKey = (
  variables?: SubscriptionPlansQueryVariables
) =>
  variables === undefined
    ? ['subscriptionPlans']
    : ['subscriptionPlans', variables];
export const SubscriptionPlanDocument = `
    query subscriptionPlan($id: ID!) {
  subscriptionPlan(id: $id) {
    id
    background
    originals
    active
    name
    offer
    prices {
      id
      price
      name
      time
      active
    }
    pip
    coin
    ads
    tvod
    download
    quality
  }
}
    `;
export const useSubscriptionPlanQuery = <
  TData = SubscriptionPlanQuery,
  TError = unknown
>(
  variables: SubscriptionPlanQueryVariables,
  options?: UseQueryOptions<SubscriptionPlanQuery, TError, TData>
) =>
  useQuery<SubscriptionPlanQuery, TError, TData>(
    ['subscriptionPlan', variables],
    fetchData<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
      SubscriptionPlanDocument,
      variables
    ),
    options
  );

useSubscriptionPlanQuery.getKey = (
  variables: SubscriptionPlanQueryVariables
) => ['subscriptionPlan', variables];
export const TalkShowsDocument = `
    query talkShows($query: PaginationQueryInput) {
  talkShowAll(query: $query) {
    items {
      id
      thumbnail
      title
      description
    }
    count
  }
}
    `;
export const useTalkShowsQuery = <TData = TalkShowsQuery, TError = unknown>(
  variables?: TalkShowsQueryVariables,
  options?: UseQueryOptions<TalkShowsQuery, TError, TData>
) =>
  useQuery<TalkShowsQuery, TError, TData>(
    variables === undefined ? ['talkShows'] : ['talkShows', variables],
    fetchData<TalkShowsQuery, TalkShowsQueryVariables>(
      TalkShowsDocument,
      variables
    ),
    options
  );

useTalkShowsQuery.getKey = (variables?: TalkShowsQueryVariables) =>
  variables === undefined ? ['talkShows'] : ['talkShows', variables];
export const TalkShowDocument = `
    query talkShow($id: ID!) {
  talkShow(id: $id) {
    id
    thumbnail
    title
    description
    artists {
      id
      avatar
      name
    }
    poster
    trailer
    releasedDate
  }
}
    `;
export const useTalkShowQuery = <TData = TalkShowQuery, TError = unknown>(
  variables: TalkShowQueryVariables,
  options?: UseQueryOptions<TalkShowQuery, TError, TData>
) =>
  useQuery<TalkShowQuery, TError, TData>(
    ['talkShow', variables],
    fetchData<TalkShowQuery, TalkShowQueryVariables>(
      TalkShowDocument,
      variables
    ),
    options
  );

useTalkShowQuery.getKey = (variables: TalkShowQueryVariables) => [
  'talkShow',
  variables
];
export const TalkShowEpisodesDocument = `
    query talkShowEpisodes($showId: ID!, $query: PaginationQueryInput) {
  talkShowEpisodes(showId: $showId, query: $query) {
    items {
      id
      thumbnail
      number
      title
      description
      mediaUrl
    }
    count
  }
}
    `;
export const useTalkShowEpisodesQuery = <
  TData = TalkShowEpisodesQuery,
  TError = unknown
>(
  variables: TalkShowEpisodesQueryVariables,
  options?: UseQueryOptions<TalkShowEpisodesQuery, TError, TData>
) =>
  useQuery<TalkShowEpisodesQuery, TError, TData>(
    ['talkShowEpisodes', variables],
    fetchData<TalkShowEpisodesQuery, TalkShowEpisodesQueryVariables>(
      TalkShowEpisodesDocument,
      variables
    ),
    options
  );

useTalkShowEpisodesQuery.getKey = (
  variables: TalkShowEpisodesQueryVariables
) => ['talkShowEpisodes', variables];
export const TrendingsDocument = `
    query trendings($query: PaginationQueryInput) {
  trending(query: $query) {
    items {
      id
      rank
      item {
        __typename
        ... on Movie {
          id
          title
          thumbnail
        }
        ... on Series {
          id
          title
          thumbnail
        }
        ... on Music {
          id
          title
          thumbnail
        }
        ... on TalkShow {
          id
          title
          thumbnail
        }
      }
    }
    count
  }
}
    `;
export const useTrendingsQuery = <TData = TrendingsQuery, TError = unknown>(
  variables?: TrendingsQueryVariables,
  options?: UseQueryOptions<TrendingsQuery, TError, TData>
) =>
  useQuery<TrendingsQuery, TError, TData>(
    variables === undefined ? ['trendings'] : ['trendings', variables],
    fetchData<TrendingsQuery, TrendingsQueryVariables>(
      TrendingsDocument,
      variables
    ),
    options
  );

useTrendingsQuery.getKey = (variables?: TrendingsQueryVariables) =>
  variables === undefined ? ['trendings'] : ['trendings', variables];
export const UsersDocument = `
    query users($query: PaginationQueryInput) {
  users(query: $query) {
    items {
      id
      name
      phone
    }
    count
    query {
      take
      skip
    }
  }
}
    `;
export const useUsersQuery = <TData = UsersQuery, TError = unknown>(
  variables?: UsersQueryVariables,
  options?: UseQueryOptions<UsersQuery, TError, TData>
) =>
  useQuery<UsersQuery, TError, TData>(
    variables === undefined ? ['users'] : ['users', variables],
    fetchData<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
    options
  );

useUsersQuery.getKey = (variables?: UsersQueryVariables) =>
  variables === undefined ? ['users'] : ['users', variables];
