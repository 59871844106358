import {
  Typography,
  IconButton,
  Icon,
  Divider,
  Chip,
  Modal,
  TextField,
  ListItemButton,
  ListItemText,
  Button
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { Center } from '../../Center';
import { ModalContent } from '../../ModalContent';
import { useSearchQuery } from '../../../generated/graphql';
import { LoadingButton } from '@mui/lab';

type ISearchEntity = {
  id: string;
  title: string;
};

export type IRecommendationInputProps = {
  type: string;
  recommendations: ISearchEntity[];
  onSave: (r: ISearchEntity[]) => Promise<void>;
};

type RecommendationModalProps = IRecommendationInputProps & {
  open: boolean;
  onCancel: () => void;
};

const useSearchRequest = (type: string) => {
  const [currentKeyword, setCurrentKeyword] = useState('');
  const { data: searchRes, isLoading: loading } = useSearchQuery({
    keyword: currentKeyword,
    filter: `__type:${type}`
  });
  const [data, setData] = useState<ISearchEntity[]>([]);

  useEffect(() => {
    setData((searchRes?.search ?? []) as any);
  }, [searchRes?.search]);

  return {
    data,
    loading,
    loadSearch: setCurrentKeyword
  };
};

const RecommendationModal: FC<RecommendationModalProps> = ({
  type,
  open,
  recommendations,
  onSave,
  onCancel
}) => {
  const { data: searchData, loadSearch } = useSearchRequest(type);
  const [selected, setSelected] = useState<ISearchEntity[]>([]);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    setSelected(recommendations);
  }, [recommendations]);

  useEffect(() => {
    setSelectedIds(new Set(selected.map(r => r.id)));
  }, [selected]);

  return (
    <Modal open={open}>
      <ModalContent
        sx={{
          width: '35%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ px: 2, mt: 2 }}>
          <Typography variant="h5">Recommendations</Typography>
          <Divider sx={{ mb: 2 }} />
          <TextField
            fullWidth
            variant="standard"
            label="Search"
            onChange={e => {
              loadSearch(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ overflowY: 'auto', flexGrow: 1, my: 1.5 }}>
          <Box sx={{ my: 1, mx: 2 }}>
            {selected.map(a => (
              <Chip
                key={a.id}
                label={a.title}
                sx={{ m: 0.2 }}
                variant="outlined"
                color="info"
                onDelete={() => {
                  setSelected(s => s.filter(b => b.id !== a.id));
                }}
              />
            ))}
          </Box>
          <Divider />
          {searchData
            .filter(a => !selectedIds.has(a.id))
            .map((a, idx) => (
              <React.Fragment key={a.id}>
                <ListItemButton
                  onClick={() => {
                    setSelected(s => [...s, a]);
                  }}
                >
                  <ListItemText primary={a.title} />
                </ListItemButton>
                {searchData.length - 1 !== idx && <Divider />}
              </React.Fragment>
            ))}
        </Box>
        <Box
          sx={{
            pb: 2,
            px: 2,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <LoadingButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            onClick={async () => {
              if (onSave) {
                await onSave(selected);
              }
            }}
          >
            Save
          </LoadingButton>
          <Button variant="contained" color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const RecommendationInput: FC<IRecommendationInputProps> = ({
  type,
  recommendations,
  onSave
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">Recommendations</Typography>
        <IconButton
          size="small"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Box>
      <RecommendationModal
        type={type}
        open={modalOpen}
        recommendations={recommendations}
        onSave={async r => {
          if (onSave) {
            await onSave(r);
          }

          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      />
      <Divider sx={{ mb: 1 }} />
      {recommendations.length === 0 && (
        <Center sx={{ p: 2 }}>No Recommendations</Center>
      )}
      {recommendations.map(r => (
        <Chip
          key={r.id}
          label={r.title}
          variant="outlined"
          color="info"
          clickable
          sx={{ m: 0.2 }}
        />
      ))}
    </Box>
  );
};
