import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Avatar,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import React, { FC, useEffect, useState } from 'react';
import {
  ArtistDataInput,
  ArtistDataUpdateInput
} from '../../generated/graphql';
import { useFile } from '../../hooks';
import { getChanged, isEmpty } from '../../utils';

export type ArtistFormProps = {
  data?: ArtistDataInput;
  onSave?: (data: ArtistDataInput) => Promise<void>;
  onUpdate?: (data: ArtistDataUpdateInput) => Promise<void>;
};

export const ArtistForm: FC<ArtistFormProps> = ({ data, onSave, onUpdate }) => {
  const theme = useTheme();
  const [editState] = useState(() => {
    if (onSave) return 'add' as const;
    if (onUpdate) return 'update' as const;
    return undefined;
  });
  const [changedState, setChangedState] = useState<ArtistDataUpdateInput>({});
  const { setFile: setAvatar, url: avatarUrl, upload } = useFile();
  const { values, isSubmitting, handleChange, handleSubmit } =
    useFormik<ArtistDataInput>({
      initialValues: data ?? {
        name: '',
        avatar: null,
        gender: 'M',
        address: null,
        bio: null,
        dob: null
      },
      onSubmit: async data => {
        if (onSave || onUpdate) {
          const { location } = await upload();

          if (onSave) {
            await onSave({
              ...data,
              avatar: location
            });
          } else if (onUpdate) {
            await onUpdate({
              ...changedState,
              ...(location && { avatar: location })
            });
          }
        }
      }
    });
  const [dob, setDob] = useState<Moment | null>(
    data?.dob ? moment(data.dob) : null
  );

  useEffect(() => {
    if (dob?.isValid()) {
      handleChange('dob')(dob.format('YYYY-MM-DD'));
    }
  }, [dob, handleChange]);

  useEffect(() => {
    if (editState === 'update') {
      setChangedState(getChanged(data, values));
    }
  }, [data, editState, values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <input
            id="artist-form-avatar-input"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={e => {
              if (e.target.files) {
                setAvatar(e.target.files[0]);
              }
            }}
          />
          <Avatar
            alt="Name"
            sx={{
              width: 110,
              height: 110,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                boxShadow: `0 0 10px 1px ${theme.palette.text.secondary}`
              }
            }}
            component="label"
            htmlFor="artist-form-avatar-input"
            src={avatarUrl ?? values.avatar ?? ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            value={values.name}
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tag"
            name="tag"
            fullWidth
            value={values.tag ?? ''}
            onChange={handleChange('tag')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="artist-form-gender-label">Gender</InputLabel>
            <Select
              labelId="artist-form-gender-label"
              label="Gender"
              name="gender"
              fullWidth
              value={values.gender ?? ''}
              onChange={e => {
                handleChange('gender')(e.target.value ?? '');
              }}
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            value={dob}
            disableFuture
            inputFormat="DD/MM/YYYY"
            onChange={(e: Moment | null) => {
              setDob(e);
            }}
            renderInput={props => (
              <TextField
                label="Date of Birth"
                name="dob"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            name="address"
            fullWidth
            multiline
            minRows={4}
            value={values.address ?? ''}
            onChange={handleChange('address')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Bio"
            name="bio"
            fullWidth
            multiline
            minRows={4}
            value={values.bio ?? ''}
            onChange={handleChange('bio')}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={
              isSubmitting ||
              (editState === 'update' && isEmpty(changedState) && !avatarUrl)
            }
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<Icon>save</Icon>}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
