import { useState } from 'react';
import { PaginationQuery } from '../generated/graphql';

export const usePaginationState = (): {
  page: number;
  pageSize: number;
  query: PaginationQuery;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
} => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const query: PaginationQuery = {
    skip: page * pageSize,
    take: pageSize
  };

  return {
    page,
    pageSize,
    query,
    setPage,
    setPageSize
  };
};
