import {
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/system';
import React, { FC, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDrawer } from '../store/ui';

const openedMixin = (theme: Theme, drawerWidth: number): CSSProperties => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSProperties => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

export const SideDrawer: FC = () => {
  const { open, width, setOpen } = useDrawer(e => ({
    open: e.open,
    width: e.width,
    setOpen: e.setOpen
  }));
  const theme = useTheme();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const pushIfNot = useCallback(
    (path: string) => {
      if (pathname !== path) {
        push(path);
      }
    },
    [pathname, push]
  );

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme, width),
          '& .MuiDrawer-paper': openedMixin(theme, width)
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme)
        })
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1)
        }}
      >
        <IconButton onClick={() => setOpen(false)} size="large">
          <Icon>chevron_left</Icon>
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <ListItemButton
          onClick={() => pushIfNot('/')}
          selected={pathname === '/'}
        >
          <ListItemIcon>
            <Icon>dashboard</Icon>
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/users')}
          selected={pathname.startsWith('/users')}
        >
          <ListItemIcon>
            <Icon>people</Icon>
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/artist/list')}
          selected={pathname.startsWith('/artist')}
        >
          <ListItemIcon>
            <Icon>contacts</Icon>
          </ListItemIcon>
          <ListItemText primary="Artist" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/genre')}
          selected={pathname.startsWith('/genre')}
        >
          <ListItemIcon>
            <Icon>category</Icon>
          </ListItemIcon>
          <ListItemText primary="Genre" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/movie/list')}
          selected={pathname.startsWith('/movie')}
        >
          <ListItemIcon>
            <Icon>movie</Icon>
          </ListItemIcon>
          <ListItemText primary="Movie" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/series/list')}
          selected={pathname.startsWith('/series')}
        >
          <ListItemIcon>
            <Icon>theaters</Icon>
          </ListItemIcon>
          <ListItemText primary="Series" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/music/list')}
          selected={pathname.startsWith('/music')}
        >
          <ListItemIcon>
            <Icon>music_note</Icon>
          </ListItemIcon>
          <ListItemText primary="Music" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/show/list')}
          selected={pathname.startsWith('/show')}
        >
          <ListItemIcon>
            <Icon>slideshow</Icon>
          </ListItemIcon>
          <ListItemText primary="Talk Show" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/mobile')}
          selected={pathname.startsWith('/mobile')}
        >
          <ListItemIcon>
            <Icon>smartphone</Icon>
          </ListItemIcon>
          <ListItemText primary="Mobile" />
        </ListItemButton>

        <ListItemButton
          onClick={() => pushIfNot('/subscription/plan/list')}
          selected={pathname.startsWith('/subscription/plan')}
        >
          <ListItemIcon>
            <Icon>hd</Icon>
          </ListItemIcon>
          <ListItemText primary="Subscription Plan" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};
