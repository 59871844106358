import { Box, Card, Icon, Typography } from '@mui/material';
import React, { FC } from 'react';
import cartouche from '../cartoucheDark.png';

export interface CardWithIconProps {
  icon: string;
  title?: string;
  subtitle?: string | number;
}

export const CardWithIcon: FC<CardWithIconProps> = ({
  icon,
  title,
  subtitle
}) => {
  return (
    <Card
      sx={{
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        flexShrink: 1,
        margin: 1
      }}
    >
      <Box
        sx={{
          overflow: 'inherit',
          padding: 2,
          background: `url(${cartouche}) no-repeat`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box width="3em" sx={{ color: 'inherit' }}>
          <Icon fontSize="large" sx={{ fontSize: '2.25rem' }}>
            {icon}
          </Icon>
        </Box>
        <Box textAlign="right">
          <Typography color="textSecondary">{title}</Typography>
          {subtitle && (
            <Typography variant="h5" component="h2">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};
