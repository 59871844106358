import React, { FC, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES, { IRoute } from './routes';

const parseRoutes = (
  route: IRoute,
  prevRoute: string | null = null,
  parsedRoutes: Array<Required<Omit<IRoute, 'children'>>> = []
) => {
  const path = prevRoute
    ? `${prevRoute}/${route.path}`.replaceAll(/[/]+/g, '/')
    : route.path;

  if (route.children) {
    route.children.forEach(child => {
      parseRoutes(child, path, parsedRoutes);
    });
  }

  if (route.component) {
    parsedRoutes.push({
      path,
      component: route.component
    });
  }
};

const Routes: FC = () => {
  const routes = useMemo(() => {
    const r: Array<Required<Omit<IRoute, 'children'>>> = [];
    parseRoutes(ROUTES, null, r);

    return r;
  }, []);

  return (
    <Switch>
      {routes.map(({ path, component }) => (
        <Route key={path} path={path} exact component={component} />
      ))}
    </Switch>
  );
};

export default Routes;
